import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { AdalService } from 'adal-angular4';


@Injectable({
  providedIn: 'root'
})
export class AuthCriptografiaService {
 private encryptKey:string;
  constructor() { }

  encryptData(data:string, encryptKey: string) {
       this.encryptKey = encryptKey;
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptKey).toString();
    } catch (e) {
      console.log(e);
    }
  }

  decryptData(data:string, encryptKey: string) {
    this.encryptKey = encryptKey;
    try {
      const bytes = CryptoJS.AES.decrypt(data, this.encryptKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }
}
