import { Injectable } from '@angular/core';
import { Email } from '../models/email.model';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const APIURL = environment.api_url_comosdb_email;
// const URL = 'https://azbrsp1apih001.azure-api.net/email/v1/mensagens';
// const HttpOptions = {
//   headers: new HttpHeaders({
//     'Accept':  'application/json',
//     'Content-Type':  'application/json',
//     'Access-Control-Allow-Origin': '*',
//   })
// };
const headers = environment.HttpHeaders;
const httpOptions = { headers };

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private http: HttpClient) { }

  sendEmail(email: Email): Observable<any> {
    return this.http.post<Email>(`${APIURL}/mensagens`, email, httpOptions).pipe(
      tap(_ => console.log(`Response sendEmail`)),
      catchError(this.handleError<any>('sendEmail', null))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

}
