import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AdalService } from 'adal-angular4';
import { AuthCriptografiaService } from './auth-criptografia.service';
import { environment } from 'src/environments/environment';

const APIURL = environment.key;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private adal: AdalService,
                private authCript: AuthCriptografiaService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.adal.userInfo.authenticated) {
            req = req.clone({
            setHeaders: {
                'Content-Type' : 'application/json; charset=utf-8',
                'Ocp-Apim-Subscription-Key': `${APIURL}`,
                'Accept'       : 'application/json',
                'Authorization': `Bearer ${this.authCript.encryptData(this.adal.userInfo.token, this.adal.userInfo.token)}`
            },
        });
        }

            return next.handle(req);
    }

    // encryptData(data) {

    //     try {
    //       return CryptoJS.AES.encrypt(JSON.stringify(data), this.adal.userInfo.token).toString();
    //     } catch (e) {
    //       console.log(e);
    //     }
    //   }

    //   decryptData(data) {

    //     try {
    //       const bytes = CryptoJS.AES.decrypt(data, this.adal.userInfo.token);
    //       if (bytes.toString()) {
    //         return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    //       }
    //       return data;
    //     } catch (e) {
    //       console.log(e);
    //     }
    //   }
}



