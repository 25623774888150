import { Component, OnInit } from '@angular/core';
import { AdalService } from 'adal-angular4';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioService } from '../usuarios/usuario/usuario.service';
import { environment } from 'src/environments/environment';

const descAmbiente = environment.descicaoAmbiente;

@Component({
  selector: 'app-navbar',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavMenuComponent implements OnInit {
  isExpanded = false;
  ambiente: any;
  userEmail: any;
  usuario: any;
  imgPath = '../../assets/images/LogoBrancaValia.png';
  navbarOpen = false;

  public sidebarOpened = false;
  toggleOffcanvas() {
    this.sidebarOpened = !this.sidebarOpened;
    if (this.sidebarOpened) {
      document.querySelector('.sidebar-offcanvas').classList.add('active');
    } else {
      document.querySelector('.sidebar-offcanvas').classList.remove('active');
    }
  }

  constructor(
    private adal: AdalService,
    config: NgbDropdownConfig,
    private usuarioService: UsuarioService, ) {
    config.placement = 'bottom-right';
    this.ambiente = descAmbiente;
  }

  sair() {
    localStorage.removeItem('token');
    this.adal.logOut();
  }

  public getEmailUser() {
    if (this.adal.userInfo.authenticated) {
        this.usuario = this.adal.userInfo;
        this.usuarioService.getEmailUser(this.usuario.profile.email).subscribe(res => {
        this.userEmail = res.usuario;
      });
    }
  }

  ngOnInit() {
    this.getEmailUser();
  }
}
