import { Component, OnInit, Input } from '@angular/core';
import { Documento } from '../models/documento.model';
import { UploaderService } from './upload.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Arquivo } from '../models/arquivo.model';
import { MessageService } from '../shared/message.service';
import { ProcessoService } from '../processo/processo.service';
import { Processo } from '../models/processo.model';
import { reject } from 'q';
import { UsuarioService } from '../usuarios/usuario/usuario.service';
import { Usuario } from '../models/usuario.model';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { AdalService } from 'adal-angular4';
import { RelatorioService } from '../relatorio/relatorio.service'
import { RegistroAtividade } from '../models/registro-atividade.model';
import { TipoAtividade } from '../models/tipo-atividade';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as fileSaver from 'file-saver';


@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css'],
  providers: [UploaderService]
})

export class UploadComponent implements OnInit {
  processos: Array<Processo> = [];
  arquivos: Array<Arquivo> = [];
  documento: Documento;
  usuario: Usuario;
  registroAtividade: RegistroAtividade;
  arquivo: Arquivo;
  size: any;
  unit: string = 'MB';
  profileForm: FormGroup;
  error: string;
  fileUpload = { status: 'initial', message: 0, filePath: '' };
  isDisabled = true;
  zipFile: JSZip;
  title = 'PTA';
  closeResult: string;
  modalOptions: NgbModalOptions;
  modalRef: NgbModalRef;
  sucessRequest = false;

  constructor(
    private uploaderService: UploaderService,
    private fb: FormBuilder,
    public messageService: MessageService,
    private processoService: ProcessoService,
    private usuarioService: UsuarioService,
    private relatorioSerivce: RelatorioService,
    private adal: AdalService,
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private router: Router) {
      this.createForm();
     }

  ngOnInit() {
    this.messageService.clear();
    this.getUsuarioPorEmail();
    this.getProcessos();
  }

  createForm() {
    this.profileForm = this.fb.group({
      name: ['', [Validators.required]],
      profile: ['', [Validators.required]]
    });
  }

  resetForm() {
    this.isDisabled = true;
    this.profileForm.reset({});
  }

  onSelectedFile(event) {
    this.messageService.clear();
      if (event.target.files[0].name.split('.').pop().toLowerCase() === 'txt') {
        if (event.target.files[0].size === 0){
          this.showErrorCreate('O arquivo está sem conteúdo.');
          this.isDisabled = true;
        } else{
          let file: any;
            this.uploadCheckCaracteres(event.target.files[0]).then(res => {
              file = res;
              if (file.name.match(/\d{1,3}\_.+\_\d{6}|\d{8}\.txt/)) {
                this.isDisabled = false;
                this.profileForm.get('profile').setValue(file);
              } else {
                this.showErrorCreate('O arquivo - ${file.name}, está com sua descrição fora do padrão esperado codigo_processo_anomesdia');
                this.isDisabled = true;
              }
            });
        }
      } else if (event.target.files[0].name.split('.').pop().toLowerCase() === 'xlsx') {
        if (event.target.files[0].name.match(/\d{1,3}\_.+\_\d{6}|\d{8}\.txt/)) {
          this.isDisabled = false;
          this.profileForm.get('profile').setValue(event.target.files[0]);
        } else {
          // tslint:disable-next-line: max-line-length
          this.showErrorCreate('O arquivo - ${event.target.files[0].name}, está com sua descrição fora do padrão esperado codigo_processo_anomesdia');
          this.isDisabled = true;
        }
      } else if (event.target.files[0].name.split('.').pop().toLowerCase() === 'xls') {
        if (event.target.files[0].name.match(/\d{1,3}\_.+\_\d{6}|\d{8}\.txt/)) {
          this.isDisabled = false;
          this.profileForm.get('profile').setValue(event.target.files[0]);
        } else {
          // tslint:disable-next-line: max-line-length
          this.showErrorCreate('O arquivo - ${event.target.files[0].name}, está com sua descrição fora do padrão esperado codigo_processo_anomesdia');
          this.isDisabled = true;
        }
      } else {
        this.isDisabled = true;
      }
  }

  onSubmit() {
    const formData = new FormData();
    formData.append('name', this.profileForm.get('name').value);
    formData.append('profile', this.profileForm.get('profile').value);
    const file = this.profileForm.get('profile').value;
    this.getBase64(file).then(res => {
      const documentoPart: any[] = file.name.split('_', file.length);
      const nomeArquivo = file.name.substring(file.name.indexOf('_') + 1, file.name.lastIndexOf('_'));
      const codigoEmpresa = this.usuario.grupo.empresas.map(emp => emp.codigo.toUpperCase().trim());
      const codigoFiltrado = codigoEmpresa.includes(documentoPart[0]) ;
      //const codigoFiltrado = codigoEmpresa.includes(documentoPart[0].toUpperCase().trim() || documentoPart[0].toLowerCase().trim()) ;
      const nomeProcesso = this.usuario.grupo.enviarProcessos.map(proc => proc.nome.toLowerCase().trim());
      const nomeProcessoFiltrado = nomeProcesso.includes(nomeArquivo.toLowerCase().trim());
      this.registroAtividade = new RegistroAtividade();
      if (codigoFiltrado && nomeProcessoFiltrado) {
        this.isDisabled = true;
        this.documento = {
          'nomeArquivo': file.name,
          'data': `${new Date().getDate().toString()}/${new Date().getMonth() + 1}/${new Date().getFullYear().toString()} ${new Date().getHours().toString()}:${new Date().getMinutes().toString()}:${new Date().getSeconds().toString()}`,
          'tipo': 'upload',
          'processo': nomeArquivo,
          'grupo': this.usuario.grupo.nome,
          'usuario': this.usuario.nome,
          'conteudoBase64': res
        };
        //#region Preencher obj Registro atividade.
        // this.registroAtividade = {
        //   // id: string;
        //   tipo: TipoAtividade.upload,
        //   nomeProcesso: nomeArquivo,
        //   idGrupo: this.usuario.grupo.id,
        //   nomeGrupo: this.usuario.grupo.nome,
        //   usuario: this.usuario,
        //   nomeArquivo: file.name,
        //   dataHora: new Date()
        // };
        //#endregion

        console.log('Qtd base64:' + this.documento.conteudoBase64.length);
        this.uploaderService.enviarDocumento(formData, this.documento).subscribe(
          _res => {
            this.fileUpload = _res;
            console.log(_res);
            if (_res.name === 'TimeoutError' || _res.name === 'HttpErrorResponse') {
              this.showErrorCreate('Erro ao tentar fazer o envio do arquivo! Mensagem: ' + _res.message);
            }
            if (_res === 201) {
               this.messageService.clear();
               this.showSucessCreate(`Arquivo ${file.name} enviado com sucesso.`);
               this.resetForm();
               this.ngOnInit();
               this.sucessRequest = true;
            }
          }, error => {
            const erro: number = error || error.messsage;
            this.showErrorCreate('Erro ao tentar fazer o envio do arquivo! Mensagem: ' + erro);
          });
          //#region Post registro de atividade.
          // () => {
          //   this.relatorioSerivce.createRegistroAtividade(this.registroAtividade).subscribe(
          //     () => {
          //       this.showSucessCreate(`Arquivo ${file.name} enviado com sucesso.`);
          //       this.resetForm();
          //     }, error => {
          //       const erro: number = error || error.messsage;
          //       this.showErrorCreate(`${erro} ${file.name} `);
          //     });
          // });
          //#endregion
      } else if (codigoFiltrado === false) {
        if (codigoEmpresa.includes(documentoPart[0].toUpperCase().trim() || documentoPart[0].toLowerCase().trim())) {
          // tslint:disable-next-line: max-line-length
          this.showErrorCreate(`Erro ao tentar enviar o arquivo, empresa de código: ${documentoPart[0]}, verifique se o nome está maiúsculo. `);
        } else {
          // tslint:disable-next-line: max-line-length
          this.showErrorCreate(`Empresa de código: ${documentoPart[0]}, não cadastrada para o grupo  deste usuário, as empresas cadastradas são: ${codigoEmpresa}`);
        }
      } else if (nomeProcessoFiltrado === false) {
        this.showErrorCreate('Nome processo: ' + nomeArquivo + ', Processo não cadastrado para o grupo de empresas deste usuário ');
                              //+ JSON.stringify(this.usuario.grupo.empresas));
      }
    });
  }

  getProcessoPorUser(processos: Array<Processo>, search: string): Promise<string> {
    const processoFind = this.processos.find(processo => processo.nome === search);
    if (processoFind) {
      return new Promise((resolve) => {
        resolve(processoFind.nome);
      });
    }
    error => reject(error);

  }

  getEmpresaPorUser(usuario: Usuario, nomeProcesso: string, codigoEmpresa: string): Promise<any> {
    const findEmpresa = usuario.grupo.empresas.find(empresa => empresa.codigo === codigoEmpresa);
    const findProcesso = usuario.grupo.enviarProcessos.find(processo => processo.nome === nomeProcesso);
    const filtrado = {
      empresa: findEmpresa,
      processo: findProcesso
    };
    if (findEmpresa && findProcesso) {
      console.log(findEmpresa);
      return new Promise((resolve) => {
        resolve(filtrado);
      });
    }
    error => reject(error);

  }

  getBase64(file): Promise<string> {
    const doc: any[] = file.name.split('_', file.length);
    if (doc.length >= 3) {
      // tslint:disable-next-line: no-shadowed-variable
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const line = reader.result.toString();
          let encoded = line.toString().replace(/^data:(.*;base64,)?/, '');
          if ((encoded.length % 4) > 0) {
            encoded += '='.repeat(4 - (encoded.length % 4));
          }
          resolve(encoded);
        };
        reader.readAsDataURL(file);
        reader.onerror = error => reject(error);
      });
    }

  }

  getLogDownload(arquivo: Arquivo) {
    const registroAtividade = new RegistroAtividade();
    this.registroAtividade = {
      tipo: TipoAtividade.download,
      nomeProcesso: arquivo.Nome.substring(arquivo.Nome.indexOf('_') + 1, arquivo.Nome.lastIndexOf('_')),
      idGrupo: this.usuario.grupo.id,
      nomeGrupo: this.usuario.grupo.nome,
      usuario: this.usuario,
      nomeArquivo: arquivo.Nome,
      dataHora: new Date()
    };

    this.relatorioSerivce.createRegistroAtividade(this.registroAtividade).subscribe(
      res => {
        console.log(res);
      }, error => {
        const erro: number = error || error.messsage;
        this.showErrorCreate(erro);
      });

  }

  teste(arquivo: Arquivo) {
    const filename = 'testeDonwload.txt';
    let data: string;
    let xhr = new XMLHttpRequest();
    xhr.open('GET', arquivo.Url, false);
    xhr.getResponseHeader('content-type');
    xhr.overrideMimeType("text/plain; charset=x-user-defined");
    xhr.onload = function (e) {
      if (this.status === 200) {
        data = this.responseText.replace('\t', ' ');
        let teste = this.response.split('\t');
      }
    };
    xhr.send();
    console.log('METODO TESTE DOWNLOAD: ' + data);
    console.log('URL ARQUIVO METODO TESTE DOWNLOAD: ' + arquivo.Url);


    const blob = new Blob([data], { type: 'text/plain; charset=x-user-defined' });
    const url= window.URL.createObjectURL(blob);
    // window.open(url);  
    fileSaver.saveAs(blob, arquivo.Nome);
   
  } 

  teste2(arquivo: Arquivo) {
    const filename = 'testeDonwload.txt';
    let data: string;
    let xhr = new XMLHttpRequest();
    xhr.open('GET', arquivo.Url, false);
    xhr.getResponseHeader('content-type');
    xhr.overrideMimeType("text/plain; charset=x-user-defined");
    xhr.onload = function (e) {
      if (this.status === 200) {
        data = this.responseText.replace('\t', ' ');
        let teste = this.response.split('\t');
      }
    };
    xhr.send();
    console.log('METODO TESTE DOWNLOAD: ' + data);
    console.log('URL ARQUIVO METODO TESTE DOWNLOAD: ' + arquivo.Url);


    const blob = new Blob([data], { type: 'text/plain; charset=x-user-defined' });
    const url= window.URL.createObjectURL(blob);
     window.open(url);  
    //fileSaver.saveAs(blob, arquivo.Nome);
   
  } 

  getProcessName(arquivo: string): string {
    if (arquivo) {
      // tslint:disable-next-line: quotemark
      return arquivo.substring(arquivo.indexOf('_') + 1, arquivo.lastIndexOf("_"));
    }
  }

  getFiles() {
    if (this.usuario.grupo) {
      const empresas = this.usuario.grupo.empresas.map(emp => emp.codigo);
      if (empresas) {
        this.uploaderService.getFiles(empresas).subscribe((res) => {
          this.arquivos = res.arquivos;
          //Colocar no HTML
          for (var _i = 0; _i < this.arquivos.length; _i++) {
            var splitNome = this.arquivos[_i].Nome.split('_');
            if(splitNome.length > 4){
              var tipoArq = splitNome[4].split('.');
              this.arquivos[_i].Nome = splitNome[0] + '_' + splitNome[1] + '_' + splitNome[2] + '_' +splitNome[3] + '.' + tipoArq[1];
            }
          }
          //
          this.profileForm.reset();
        });
      }
    }
  }

  public getProcessos() {
    this.processoService.getProcessos().subscribe((res) => {
      this.processos = res.processos;
    });
  }

  public getUsuarioPorEmail() {
    if (this.adal.userInfo.authenticated) {
      let usuarioLogado: any;
      usuarioLogado = this.adal.userInfo;
      this.usuarioService.getEmailUser(usuarioLogado.profile.email).subscribe((res) => {
        this.usuario = res.usuario;
        this.getFiles();
      });
    }
  }

  getDownloadZip() {
    this.zipFile = new JSZip();
    const filename = 'arquivos.zip';
    const arquivos = this.arquivos.map(blobFile => blobFile);
    let data: string;
    this.arquivos.forEach(obj => {
      let xhr = new XMLHttpRequest();
      xhr.open('GET', obj.Url, false);
      xhr.overrideMimeType('application\/octet-streamplain; charset=utf-8');
      // xhr.getResponseHeader('content-type');
      // xhr.overrideMimeType("text/plain; charset=x-user-defined");
      xhr.onload = function (e) {
        if (this.status === 200) {
          data = this.responseText;
        }
      };
      xhr.send();
      this.getCompactados(obj.Nome, data);
      this.getLogDownload(obj);

    });

    this.zipFile.generateAsync({ type: 'blob' }).then(function (file) {
      saveAs(file, filename);
    });
  }

  getCompactados(filename, data) {
    this.zipFile.file(filename, data, { binary: true });
  }

  showSucessCreate(msg: any) {
    this.toastrService.success(msg, 'PTA - Portal de transferência de arquivo',{
      timeOut: 500000,
      closeButton: true
    });
  }

  showErrorCreate(msg: any) {
    this.toastrService.error(msg, 'PTA - Portal de transferência de arquivo',{
      timeOut: 500000,
      closeButton: true
    });
    this.router.navigateByUrl('/upload');
    this.getFiles();
  }

  open(content) {
    this.modalRef = this.modalService.open(content);
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'Pressione ESC para sair';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'Click OK para executar a tarefa';
    } else {
      return `with: ${reason}`;
    }
  }

  uploadCheckCaracteres(file): Promise<File> {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = 'xlsx';
    const extension = file.name.split('.').pop();
    const parsed = [];
    let blob = null;
    if (file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          const line = fileReader.result.toString();
          const removeAcentos = line.normalize('NFD').replace(/[\u0300-\u036f]/gi, '');
          parsed.push(removeAcentos.toString().replace(/[^\w\s]/gi, ''));
          blob = new File(parsed, file.name);
            resolve(blob);
        };
        fileReader.readAsText(file, 'ISO-8859-1');
        fileReader.onerror = error => reject(error);
      });
    }
  }

}
