import { OnInit, Injectable } from '@angular/core';
import { AdalService } from 'adal-angular4';
import { AuthGuardService } from '../guards/auth-guard.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const perfilPpta_normativos = environment.perfisUser.pta_normativos;

@Injectable()
export class Util implements OnInit {

  constructor(
    private adal: AdalService,
    private guard: AuthGuardService) { }

  ngOnInit() {}

  verificarGrupos(): boolean {
    let retorno = false;

    const inforUser = this.guard.getDecodedAccessToken(this.adal.userInfo.token);

     if (inforUser.groups.indexOf(perfilPpta_normativos) !== -1) {
        retorno = true;
     }

    return retorno;
 }

}
