import { Component, OnInit, Injectable } from '@angular/core';
import { SistemaService } from './sistema.service';
import { Sistema } from 'src/app/models/sistema.model';
import { Processo } from 'src/app/models/processo.model';
import { ProcessoService } from 'src/app/processo/processo.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sistema',
  templateUrl: './sistema.component.html',
  styleUrls: ['./sistema.component.css']
})

export class SistemaComponent implements OnInit {
  sistemas: Array<Sistema>;
  sistema: Sistema;
  processos: Array<Processo>;
  sistemaForm: FormGroup;
  updateForm: FormGroup;
  deleteForm: FormGroup;
  dataSaved = false;
  title = 'PTA';
  closeResult: string;
  modalOptions: NgbModalOptions;
  modalRef: NgbModalRef;

  constructor(private sistemaService: SistemaService,
    private processoService: ProcessoService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private router: Router) {

    this.createForm();
    this.updateSistemaForm();
    this.deleteSistemaForm();

    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit() {
    this.getSistemas();
    this.getProcessos();
  }

  createForm() {
    this.sistemaForm = this.formBuilder.group({
      nome: ['', [Validators.required]],
      sigla: ['', [Validators.required]],
      processos: ['']
    });
  }

  updateSistemaForm() {
    this.updateForm = this.formBuilder.group({
      id: [''],
      nome: ['', [Validators.required]],
      sigla: ['', [Validators.required]],
      processos: ['', [Validators.required]]
    });
  }

  deleteSistemaForm() {
    this.deleteForm = this.formBuilder.group({
      id: [{value: '', disabled: true}],
      nome: ['', [Validators.required]],
      sigla: ['', [Validators.required]],
      processos: ['']
    });
  }

  onFormSubmit() {
    this.dataSaved = false;
    this.sistema = this.sistemaForm.value;
    this.create(this.sistema);
    this.sistemaForm.reset();
  }

  create(sistema: Sistema) {
    this.sistemaService.createSistema(sistema).subscribe(
      res => {
        this.dataSaved = true;
        this.ngOnInit();
        this.sistemaForm.reset();
        this.showSucessCreate('Registro criado com sucesso.');
    }, error => {
      let erro: number = error | error.messsage;
       this.showErrorCreate(erro);
   });
  }

  getSistemas() {
    this.sistemaService.getSistemas().subscribe((res) => {
      this.sistemas = res.sistemas;
    });
  }

  getProcessos() {
    this.processoService.getProcessos().subscribe((res) => {
      this.processos = res.processos;
    });
  }


  update(sistema: Sistema, content) {
    this.open(content);
    this.updateForm.patchValue({
      id: sistema.id,
      nome: sistema.nome,
      sigla: sistema.sigla,
      processos: sistema.processos
    });
  }

  onUpdate(updateForm) {
    let sistema = JSON.parse(JSON.stringify(updateForm.value));
    this.sistemaService.updateSistema(sistema).subscribe((res) => {
      this.ngOnInit();
      this.sistemaForm.reset();
      this.showSucessUpdate('Registro atualizado com sucesso.');
    }, error => {
      let erro: number = error | error.messsage
      this.showErrorUpdate(erro);
   });

  }

  delete(sistema, content) {
    this.open(content);
    this.deleteForm.patchValue(sistema);
  }

  onDeleteSistema(deleteForm) {
    let sistemaDelete = JSON.parse(JSON.stringify(deleteForm.value));
    this.sistemaService.deleteSistema(this.sistema.id).subscribe((res) => {
      this.showSucessDelete('Registro apagado com sucesso.');
    }, error => {
      let erro: number = error || error.messsage
      this.showErrorDelete(erro);
   });
  }



showSucessCreate(msg: any) {
  this.toastrService.success(msg);
  this.modalRef.close();
  this.router.navigateByUrl('/sistemas');
  this.getProcessos();
}

showSucessUpdate(msg: any) {
  this.toastrService.success(msg);
  this.modalRef.close();
  this.router.navigateByUrl('/sistemas');
  this.getProcessos();
}

showSucessDelete(msg: any) {
  this.toastrService.success(msg);
  this.modalRef.close();
  this.router.navigateByUrl('/sistemas');
  this.getProcessos();
}

showErrorCreate(msg: any) {
  this.toastrService.error(msg);
  this.modalRef.close();
  this.router.navigateByUrl('/sistemas');
  this.getProcessos();
}

showErrorUpdate(erro: any) {
  this.toastrService.error(erro);
  this.modalRef.close();
  this.router.navigateByUrl('/sistemas');
  this.getProcessos();
}

showErrorDelete(msg: any) {
  this.toastrService.error(msg);
  this.modalRef.close();
  this.router.navigateByUrl('/sistemas');
  this.getProcessos();
}


  open(content) {
    this.modalRef = this.modalService.open(content);
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'Pressione ESC para sair';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'Click OK para executar a tarefa';
    } else {
      return `with: ${reason}`;
    }
  }
}
