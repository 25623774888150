import { Injectable, ErrorHandler } from '@angular/core';
import {
    HttpClient, HttpEvent, HttpEventType, HttpProgressEvent,
    HttpRequest, HttpResponse, HttpErrorResponse, HttpHeaders
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap, timeout } from 'rxjs/operators';
import { Documento } from '../models/documento.model';
import { MessageService } from '../shared/message.service';
import { environment } from 'src/environments/environment';

const APIURL = environment.api_url_pta;
const headers = environment.HttpHeaders;
const httpOptions = {headers}
@Injectable()
export class UploaderService {

    constructor(private http: HttpClient, private messageService: MessageService) { }
    enviarDocumento(formData: any, documento: Documento) {
        return this.http.post<any>(`${APIURL}/arquivosPatrocinadoras`, JSON.stringify(documento), {
            headers,
            reportProgress: true,
            observe: 'events'
        }).pipe(
            timeout(300000),
            map(event => this.getEventMessage(event, formData, documento)),
            catchError(this.handleError('Envio de Documento', HttpEventType.Response))
        );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error); // log to console instead
            console.error(result);
            console.log(`${operation} failed: ${error.message}`);
            return of(error as T);
        };
    }

    private fileUploadProgress(event) {
        const percentDone = Math.round(100 * event.loaded / event.total);
            return { status: 'progress', message: percentDone};
    }

    private getEventMessage(event: HttpEvent<any>, formData, documento: Documento) {
        switch (event.type) {
            case HttpEventType.UploadProgress:
                return this.fileUploadProgress(event);
              // break;
            case HttpEventType.Response:
                return this.apiResponse(event);
                //break;
            default:
                return `File "${formData.get('profile').name}" surprising upload event: ${event.type}.`;
        }
    }

    private apiResponse(event) {
        return event.status;
    }


    getFiles(empresas: any[]): Observable<any> {
        return this.http.get<any>(`${APIURL}/arquivosdisponiveis?empresas=${empresas.join(',')}`, httpOptions)
        .pipe(
            timeout(10000),
            tap(_ => console.log(`Response getFiles`)),
            catchError(this.handleError<any>('getFiles', []))
        );
    }
}
