import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './guards/auth-guard.service';

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { UploadComponent } from './upload/upload.component';
import { UsuarioComponent } from './usuarios/usuario/usuario.component';
import { EmpresaComponent } from './empresa/empresa.component';
import { RelatorioComponent } from './relatorio/relatorio.component';
import { SistemaComponent } from './sistemas/sistema/sistema.component';
import { ProcessoComponent } from './processo/processo.component';
import { GrupoComponent } from './grupos/grupo/grupo.component';
import { MensagemComponent } from './mensagens/mensagem/mensagem.component';
import { CalendarioComponent } from './calendario/calendario.component';
import { ArquivosenviadosComponent } from './arquivosenviados/arquivosenviados.component';


const routes: Routes = [
  { path: '', component: LoginComponent, pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'upload', component: UploadComponent, canActivate: [AuthGuardService] },
  { path: 'relatorio', component: RelatorioComponent, canActivate: [AuthGuardService] },
  { path: 'usuarios', component: UsuarioComponent, canActivate: [AuthGuardService] },
  { path: 'sistemas', component: SistemaComponent, canActivate: [AuthGuardService] },
  { path: 'processos', component: ProcessoComponent, canActivate: [AuthGuardService] },
  { path: 'empresa', component: EmpresaComponent, canActivate: [AuthGuardService] },
  { path: 'grupos', component: GrupoComponent, canActivate: [AuthGuardService] },
  { path: 'mensagens', component: MensagemComponent, canActivate: [AuthGuardService] },
  { path: 'calendario', component: CalendarioComponent, canActivate: [AuthGuardService] },
  { path: 'arquivosenviados', component: ArquivosenviadosComponent, canActivate: [AuthGuardService] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
