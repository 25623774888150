// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  descicaoAmbiente: '',
  api_url_blob: '/blob/v1/arquivos',
  api_url_pta: 'https://azbrsp1apip001.azure-api.net/pta/v1',
  api_url_comosdb_blob: 'https://azbrsp1apip001.azure-api.net/blob/v1',
  api_url_comosdb_email: 'https://azbrsp1apip001.azure-api.net/email/v1',
  api_url_post_consulta: 'https://azbrsp1apip001.azure-api.net/docs-bd/v1/bds',
  api_url_post_consultaSDK: 'https://azbrsp1apip001.azure-api.net/docs-bd/v1/consultas/bds',
  key: '28fe6bccbf0f48188b8ee862f7202689',

  message: {
   id: '5a20a01d-eddd-427a-8bfd-d49a82e6f2cf'
  },
  messageSendEmail: 'Sr(a) <b>{0}</b>,<br>Você acabou de receber o acesso ao <b>novo</b> Portal de Transferência de Arquivos (PTA) da Valia.<br>Se você possuir acesso ao Portal do Participante da Valia, basta acessar o PTA com o mesmo Login (e-mail) e Senha.<br>Caso você não possua acesso ao Portal do Participante da Valia, será necessário você concluir seu cadastro clicando em “Inscreva-se agora” na tela de login.<br>Essas informações estão detalhadas no <b>Guia de Utilização.</b><br><br>Para acessar o PTA basta <a href="https://pta.valiaweb.net.br/">clicar aqui</a>',
  HttpHeaders: {
    'Content-Type': 'application/json',
    // Inicio alterações
    'Cache-Control': 'no-cache',
    'anti-csrf-token' : 'csrf_token',
    Pragma: 'no-cache',
    //Fim alterações
    'Ocp-Apim-Subscription-Key': '28fe6bccbf0f48188b8ee862f7202689'
  },
  adalConfig: {
      tenant: 'valiaonline.onmicrosoft.com',
      tenantId: '84c937a9-0a7a-4b69-b592-1c818e0460e7',
      clientId: '219cd79c-ded8-4895-afaa-ed4e367cc29e',
      redirectUri: 'https://pta-admin.valiaweb.net.br',
      postLogoutRedirectUri: window.location.origin + '/'
  },
  //  adalConfig: {
  //    tenant: 'valiaweb.net.br',
  //    tenantId: '10e0a6aa-0447-4d2e-bce8-85279ade47d0',
  //    clientId: '671b10f0-43a6-475d-a1ef-5828f047ce25',
  //    redirectUri: 'http://localhost:4200/home',
  //    //redirectUri: 'https://azapp-pta-administrador-dev-001.azurewebsites.net/',
  //    postLogoutRedirectUri: window.location.origin + '/'
  //  },
  perfisUser: {
    pta_administradores: '293aa898-6627-4e9c-91b9-55d393edde96',
    pta_normativos: '09ad09b2-a491-4e9e-84ce-326190e857e6',
    pta_tecnico: 'dd66e67d-823e-4712-9912-d68f9aebb697',
    pta_patrocinadoras_internas: '974013dc-8303-495f-a3fb-c7b7c992f6fa'
  }
};
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
