import { Component, ElementRef, OnInit, ViewChild, ɵConsole } from '@angular/core';
import { RegistroAtividade } from '../models/registro-atividade.model';
import { ExportExcelService } from '../shared/export-excel.service';
import { ArquivosenviadosService } from './arquivosEnviados.service';
import { AdalService } from 'adal-angular4';
import { Usuario } from '../models/usuario.model';
import { UsuarioService } from '../usuarios/usuario/usuario.service';
declare var $;

@Component({
  selector: 'app-arquivosEnviados',
  templateUrl: './arquivosEnviados.component.html',
  styleUrls: ['./arquivosEnviados.component.scss']
})

export class ArquivosenviadosComponent implements OnInit {
  registroAtividades: Array<RegistroAtividade> = [] ;
  registroAtividade: RegistroAtividade;
  dataSaved: boolean = false;
  @ViewChild('dataTable', {static: false}) table: ElementRef;
  dataTable: any;
  dtOptions: any;
  editor: any;
  usuario: Usuario;

  constructor(
    private arquivosEnviadosService: ArquivosenviadosService,
    private ExportExcel: ExportExcelService,
    private adal: AdalService,
    private usuarioService: UsuarioService) {
   }

   ngOnInit() {
    //this.getRegistroAtividades();
    this.getUsuarioPorEmail();
    //this.getRegistroAtividadesPorUsuario();
  }


  // getRegistroAtividades() {
  //     this.relatorioService.getRegistroAtividade().subscribe((res)=>{
  //       this.registroAtividades = res.registros;
  //       this.createTable();
  //       //console.log(this.registroAtividades)
  //     },error => {
  //       let erro:number = error || error.messsage
  //    });
  // }

  public getUsuarioPorEmail() {
    if (this.adal.userInfo.authenticated) {
      this.usuarioService.getEmailUser(this.adal.userInfo.profile.email).subscribe(res => {
        this.usuario = res.usuario;
        this.getRegistroAtividadesPorUsuario();
      });
    }
  }

  getRegistroAtividadesPorUsuario() {
    if(this.adal.userInfo.authenticated){
        let query = {'query': 'SELECT * FROM c WHERE c.nomeGrupo = ' + `'${this.usuario.grupo.nome}' ORDER BY c._ts DESC`};
        console.log(query);
        console.log(this.adal.userInfo);
        this.arquivosEnviadosService.getRegistroAtividadePorUsuarioSDK(query).subscribe((res) => {
          this.registroAtividades = res.Documents;
          let test = this.registroAtividades;
          this.createTable();
          // console.log(this.registroAtividades.length);
          // console.log(res.Documents);
        }, error => {
          let erro: number = error || error.messsage;
       });
      }
  }

  getDownload() {
    this.arquivosEnviadosService.getDownload(JSON.stringify({'registros': this.registroAtividades}))
    .subscribe((res) => {
    }, error => {
      let erro: number = error || error.messsage;
   });
  }

  createTable() {
    this.dtOptions = {
      "processing": true,
      data: this.registroAtividades,
      "language": {
        "sEmptyTable": "Nenhum registro encontrado",
        "sInfo": "Mostrando de _START_ até _END_ de _TOTAL_ registros",
        "sInfoEmpty": "Mostrando 0 até 0 de 0 registros",
        "sInfoFiltered": "(Filtrados de _MAX_ registros)",
        "sInfoPostFix": "",
        "sInfoThousands": ".",
        "sLengthMenu": "_MENU_ resultados por página",
        "sLoadingRecords": "Carregando...",
        "sProcessing": "Processando...",
        "sZeroRecords": "Nenhum registro encontrado",
        "sSearch": "Pesquisar",
        "oPaginate": {
            "sNext": "Próximo",
            "sPrevious": "Anterior",
            "sFirst": "Primeiro",
            "sLast": "Último"
        },
        "oAria": {
            "sSortAscending": ": Ordenar colunas de forma ascendente",
            "sSortDescending": ": Ordenar colunas de forma descendente"
        },
        "select": {
            "rows": {
                "_": "Selecionado %d linhas",
                "0": "Nenhuma linha selecionada",
                "1": "Selecionado 1 linha"
            }
        }
    },
      columns: [         ​ ​
          { 'data': 'nomeArquivo', render: function (data, type, row)
            {
              var re = /pta-in\/.+?_\d{6}/;
              var value = re.exec(data).toString().replace('pta-in/', '');

              return value;
            }
          },
          { "data": 'nomeProcesso' },
          { "data": 'nomeGrupo' },
          { "data": 'usuario' },
          { "data": 'dataHora', render: function ( data, type, row ) {
            //var dataCompleta = data.replace(/-/gi, '/');
            var dataHora = data.replace(/-/gi, '/').split(' ');
            var date = dataHora[0].split('/');
            var hora = (dataHora[1] + ':').split(':');
            //​​return date[0].padStart(2, '0') + '/' + date[1].padStart(2, '0') + '/' + date[2].padStart(4, '0') //+
            ​​return date[2].padStart(4, '0') + '/' + date[1].padStart(2, '0') + '/' + date[0].padStart(2, '0') +
                   ' ' + hora[0].padStart(2, '0') + ':' + hora[1].padStart(2, '0') + ':' + hora[2].padStart(2, '0');
            }
          }
      ]
    };
    this.dataTable = $(this.table.nativeElement);
    this.dataTable.DataTable(this.dtOptions);
  }

  exportAsXLSX(): void {
    this.ExportExcel.exportAsExcelFile(this.registroAtividades, 'RegistroDeAtividades');
  }

}
