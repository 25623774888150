import { Component, OnInit, Input } from '@angular/core';
import { ProcessoService } from './processo.service';
import { Processo } from '../models/processo.model';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import {NgbModal, ModalDismissReasons, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { stringify } from 'querystring';

@Component({
  selector: 'app-processo',
  templateUrl: './processo.component.html',
  styleUrls: ['./processo.component.css']
})
export class ProcessoComponent implements OnInit {
processos: Array<Processo> = [ ];
processosFilter: Array<Processo> = [ ];
processoForm: FormGroup;
updateForm: FormGroup;
deleteForm: FormGroup;
processo: Processo;
dataSaved = false;
title = 'PTA';
closeResult: string;
modalOptions: NgbModalOptions;
modalRef: NgbModalRef;
responsavel_valiaProcesso = ['Arrecadação', 'Cadastro', 'Beneficios', 'Empréstimos'];
private _searchTerm: string;

constructor(
    private processoService: ProcessoService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private router: Router) {

    this.getProcessos();

    this.createForm();
    this.updateProcessoForm();
    this.deleteProcessoForm();

    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
   }

  ngOnInit() {
    this.processoForm.reset();
  }

  createForm() {
    this.processoForm = this.formBuilder.group({
      nome: ['', [Validators.required]],
      descricao: ['', [Validators.required]],
      responsavelValia: ['', [Validators.required]]
   });

  }

  updateProcessoForm() {
    this.updateForm = this.formBuilder.group({
      id: [''],
      nome: ['', [Validators.required]],
      descricao: ['', [Validators.required]],
      responsavelValia: ['', [Validators.required]]
   });

  }

  deleteProcessoForm() {
    this.deleteForm = this.formBuilder.group({
      id: [{value: '', disabled: true}],
      nome: [{value: '', disabled: true}],
      descricao: [{value: '', disabled: true}],
      responsavelValia: [{value: '', disabled: true}]
   });

  }

  onFormSubmit() {
    this.dataSaved = false;
    this.processo = this.processoForm.value;
    this.createProcesso(this.processo);
  }

  public getProcessos() {
    this.processoService.getProcessos().subscribe((res) => {
      this.processos = res.processos;
      this.processosFilter = this.processos;
    });
  }

  // public getProcessoPorNome(nome: string) {
  //   this.processoService.getProcessoPorNome(nome).subscribe((res)=>{
  //     this.processoFilter = res.processo;
  //     console.log(this.processoFilter);
  //     });
  // }

createProcesso(processo: Processo) {
  this.processoService.createProcesso(processo).subscribe(
    res => {
      console.log(processo);
      this.dataSaved = true;
      this.getProcessos();
      this.processoForm.reset();
      if  (res.error === undefined) {
        this.showSucessCreate('Registro criado com sucesso.');
      } else {
        this.showErrorUpdate(res.error);
      }
    }, error => {
      let erro: number = error || error.messsage;
       this.showErrorUpdate(erro);
   });
}

updateProcesso(processo: Processo, content) {
  this.open(content);
  console.log(processo);
  this.updateForm.patchValue({
    id: processo.id,
    descricao: processo.descricao,
    nome: processo.nome,
    responsavelValia: processo.responsavelValia
  });

}

onUpdate(updateForm) {
  console.log(this.updateForm.value);
  let processo = JSON.parse(JSON.stringify(this.updateForm.value));
  this.processoService.updateProcesso(processo).subscribe((res) => {
     if (res.status === 'OK') {
       this.showSucessUpdate('Registro atualizado com sucesso.');
       console.log(res);
     } else {
      this.showErrorUpdate('Erro ao tentar alterar Processo, Mensagem: ' + res.status);
     }
  }, error => {
     let erro: number = error || error.messsage;
      this.showErrorUpdate(erro);
  });

}

deleteProcesso(processo, content) {
  this.open(content);
  this.deleteForm.patchValue(processo);
}

onDelete(deleteForm) {
  let processoDelete = JSON.parse(JSON.stringify(deleteForm.value));
  if(this.verificarRegistrosProcesso(processoDelete)) {
    this.processoService.deleteProcesso(processoDelete).subscribe((res) => {
        console.log(processoDelete.id);
        this.showSucessDelete('Registro apagado com sucesso.');
      }, error => {
        let erro: number = error || error.messsage;
        this.showErrorDelete(erro);
    });
  } else {
    this.showErrorDelete('Você não pode deletar o processo selecionado, já existe registro relacionado ao mesmo.');
  }
}

onChange(event, processoForm) {
  if (event.target.value) {
    processoForm.responsavelValia = event.target.value;
  }
}

showSucessCreate(msg: any) {
  this.toastrService.success(msg);
  this.modalRef.close();
  this.router.navigateByUrl('/processos');
  this.getProcessos();
}

showSucessUpdate(msg: any) {
  this.toastrService.success(msg);
  this.modalRef.close();
  this.router.navigateByUrl('/processos');
  this.getProcessos();
}

showSucessDelete(msg: any) {
  this.toastrService.success(msg);
  this.modalRef.close();
  this.router.navigateByUrl('/processos');
  this.getProcessos();
}

showErrorCreate(msg: any) {
  this.toastrService.error(msg);
  this.modalRef.close();
  this.router.navigateByUrl('/processos');
  this.getProcessos();
}

showErrorUpdate(erro: any) {
  this.toastrService.error(erro);
  this.modalRef.close();
  this.router.navigateByUrl('/processos');
  this.getProcessos();
}

showErrorDelete(msg: any) {
  this.toastrService.error(msg);
  this.modalRef.close();
  this.router.navigateByUrl('/processos');
  this.getProcessos();
}

open(content) {
  this.modalRef = this.modalService.open(content);
  this.modalRef.result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'Pressione ESC para sair';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'Click OK para executar a tarefa';
    } else {
      return  `with: ${reason}`;
    }
  }

  private verificarRegistrosProcesso(proceso: Processo): boolean {
    return false;
  }
}
