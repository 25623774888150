import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Sistema } from '../../models/sistema.model';
import { environment } from 'src/environments/environment';

const APIURL = environment.api_url_pta;
const headers = environment.HttpHeaders;
const httpOptions = { headers };
@Injectable({
  providedIn: 'root'
})
export class SistemaService {

  constructor(private http: HttpClient) { }

  getSistemas(): Observable<any> {
    return this.http.get<any>(`${APIURL}/sistemas`, httpOptions).pipe(
      tap(_ => console.log(`Response getSistemas`)),
      catchError(this.handleError<any>('getSistemas', null))
    );
  }

   createSistema(sistema: Sistema): Observable<any> {
    return this.http.post<Sistema>(`${APIURL}/sistemas`, sistema , httpOptions).pipe(
      tap(_ => console.log(`Response postSistema`)),
      catchError(this.handleError<any>('postSistema', null))
    );
  }

  updateSistema(sistema: Sistema) {
    return this.http.put<void>(`${APIURL}/sistemas`, sistema, httpOptions).pipe(
      tap(_ => console.log(`Response updateSistema`)),
      catchError(this.handleError<any>('updateSistema', null))
    );
  }

  deleteSistema(sistemaID: string) {
    return this.http.delete<Sistema>(`${APIURL}/sistemas/${sistemaID}`, httpOptions).pipe(
      tap(_ => console.log('Response deleteSistema')),
      catchError(this.handleError<Sistema>('deleteSistema', null))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
