import { Component, OnInit } from '@angular/core';
import { AdalService } from 'adal-angular4';
import { environment } from 'src/environments/environment';

import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor(private adal: AdalService) {
    this.adal.init(environment.adalConfig);
  }


  ngOnInit() {
    this.adal.handleWindowCallback();
     if (this.adal.userInfo.authenticated){
       localStorage.setItem('token', this.adal.userInfo.token);
       let token = this.getDecodedAccessToken(this.adal.userInfo.token);
       if (token.groups) {
         //alert(JSON.stringify(token.groups))
         console.log(token.groups);
         console.log(token.groups[0]);
       }
     } else {
      this.adal.login();
     }
  }

   getDecodedAccessToken(token: string): any {
     try {
       return jwt_decode(token);
     } catch (Error) {
         return null;
     }
   }


}


