import { Component, OnInit } from '@angular/core';
import { AuthGuardService } from '../guards/auth-guard.service';
import { AdalService } from 'adal-angular4';
import { environment } from 'src/environments/environment';

const perfilPta_administradores = environment.perfisUser.pta_administradores;
const perfilPpta_normativos = environment.perfisUser.pta_normativos;
const perfilPta_tecnico = environment.perfisUser.pta_tecnico;
const perfilPta_patrocinadoras_internas = environment.perfisUser.pta_patrocinadoras_internas;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  pta_administradores = false;
  pta_normativos = false;
  pta_tecnico = false;
  pta_patrocinadoras_internas = false;

  constructor(
    private guard: AuthGuardService,
    private adal: AdalService) { }

  ngOnInit() {
      if (this.guard.canActivate(this.adal.userInfo.authenticated, this.adal.userInfo.token)) {
            const inforUser = this.guard.getDecodedAccessToken(this.adal.userInfo.token);
            if (inforUser.groups) {
              this.recuperarGrupos(inforUser.groups);
            }
      }
  }

  // Método que verifica os perfis cadastrado para o usuário.
   recuperarGrupos(grupos: string[]) {
      if (grupos.indexOf(perfilPta_administradores) !== -1) {
        this.pta_administradores = true;
      }
      if (grupos.indexOf(perfilPpta_normativos) !== -1) {
        this.pta_normativos = true;
      }
      if (grupos.indexOf(perfilPta_tecnico) !== -1) {
        this.pta_tecnico = true;
      }
      if (grupos.indexOf(perfilPta_patrocinadoras_internas) !== -1) {
        this.pta_patrocinadoras_internas = true;
      }
   }
}
