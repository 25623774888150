import { Component, OnInit, ɵConsole, ElementRef, ViewChild } from '@angular/core';
import { UsuarioService } from './usuario.service';
import { EmailService } from '../../shared/email.service';
import { Usuario } from '../../models/usuario.model';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Grupo } from 'src/app/models/grupo.model';
import { GrupoService } from 'src/app/grupos/grupo/grupo.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AdalService } from 'adal-angular4';
import { Util } from '../../Util/util';
import { Email } from '../../models/email.model';
import { environment } from 'src/environments/environment';
import { UsuarioExport } from 'src/app/models/usuarioExport.model';

declare var $;

const sendEmail = environment.messageSendEmail;

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css']
})
export class UsuarioComponent implements OnInit {
  userEmail: any;
  usuario: Usuario;
  usuariosCheckMatricula: Array<Usuario> = [];
  usuarios: Array<Usuario> = [];
  arrayExport: Array<UsuarioExport> [];
  grupos: Array<Grupo> = [];
  usuarioForm: FormGroup;
  updateForm: FormGroup;
  deleteForm: FormGroup;
  dataSaved = false;
  title = 'PTA';
  closeResult: string;
  modalOptions: NgbModalOptions;
  modalRef: NgbModalRef;
  tipoPerfil = ['Patrocinadora', 'Consignatária'];
  perfilNormativo = false;
  habilitarMatricula: boolean;
  
  chaveAtualizada: string;

  dtOptions: any;
  dataTable: any;
  @ViewChild('dataTable', {static: false}) table: ElementRef;
  usuariosTable: Array<Usuario> = [] ;

  constructor(
    private emailService: EmailService,
    private usuarioService: UsuarioService,
    private adal: AdalService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private grupoService: GrupoService,
    private toastrService: ToastrService,
    private router: Router,
    private util: Util) {
      this.getUsuariosExport();
      this.getUsuarios();
      this.getGrupos();
      this.createForm();
      this.updateUsuarioForm();
      this.deleteUsuarioForm();
      this.perfilNormativo = this.util.verificarGrupos();
      this.modalOptions = {
        backdrop: 'static',
        backdropClass: 'customBackdrop'
      };
  }

  ngOnInit() {
    this.getEmailUser();
    this.usuarioForm.reset();
  }

  resetPage(){
    this.getUsuariosExport();
    this.getUsuarios();
    this.getGrupos();
    this.createForm();
    this.updateUsuarioForm();
    this.deleteUsuarioForm();
  }

  createForm() {
    this.usuarioForm = this.formBuilder.group({
      chave: [this.chaveAtualizada, [Validators.required]],
      nome: ['', [Validators.required]],
      status: ['ativo'],
      perfil: ['', [Validators.required]],
      email: ['', [Validators.required]],
      grupo: ['', [Validators.required]]
   });

  }

  updateUsuarioForm() {
    this.updateForm = this.formBuilder.group({
      id: [''],
      chave: [''],
      status: [''],
      nome: ['', [Validators.required]],
      perfil: ['', [Validators.required]],
      email: ['', [Validators.required]],
      grupo: ['', [Validators.required]]
   });

  }

  deleteUsuarioForm() {
    this.deleteForm = this.formBuilder.group({
      id: [{value: '', disabled: true}],
      chave: [{value: '', disabled: true}],
      status: [{value: '', disabled: true}],
      nome: [{value: '', disabled: true}],
      perfil: [{value: '', disabled: true}],
      email: [{value: '', disabled: true}],
      grupo: [{value: '', disabled: true}]
   });

  }

  onFormSubmit() {
    this.dataSaved = false;
    let usuario = this.usuarioForm.value;
    this.create(this.usuario);
    this.usuarioForm.reset();
  }

  public getEmailUser() {
    let usuario:any;
    if(this.adal.userInfo.authenticated) {
      usuario = this.adal.userInfo;
    }
    this.usuarioService.getEmailUser(usuario.profile.email).subscribe(res => {
      this.userEmail = res.usuario;
    });
  }

  public getUsuarios() {
    this.usuarioService.getUsuarios().subscribe((res) => {
      this.usuarios = res.usuarios.filter(user => user.status === 'ativo');    
      this.usuariosCheckMatricula = res.usuarios;

      console.log(this.usuarios.length);
      console.log(this.usuariosCheckMatricula.length);

      if (this.userEmail) {
        this.usuario = this.usuarios.find(user => user.id === this.userEmail.id);
        
        //Tratamento recuperaçãoãlteração da chave
        var objetoMaiorValor = this.usuariosCheckMatricula.sort((a, b) => (a.chave > b.chave ? -1 : 1))[0]; //DESC
        var chave = Number(objetoMaiorValor.chave.replace(/[^0-9]/g, ''));
        var chaveTeste =  (chave + 1).toString();
        this.chaveAtualizada =  'PTA' + chaveTeste.padStart(5, '0');

        this.createForm();
      }
      //this.createTable();
    });
  }

  public getUsuariosExport() {
    this.usuarioService.getUsuariosExport().subscribe((res) => {
      this.arrayExport = res.usuarios;
    });
  }

  public getGrupos() {
    this.grupoService.getGrupos().subscribe((res) => {
      this.grupos = res.grupos;
    });
  }

  create(usuario: Usuario) {
     this.usuarioService.create(usuario).subscribe(
      res => {
        this.dataSaved = true;
        this.usuarioForm.reset();
        if (res) {
          if (res.error === 'Já existe um usuário cadastrado com o email informado') {
            this.showErrorCreate(res.error);
          } else {
            const emailSendCadastro = new Email();
            emailSendCadastro.assunto = 'Acesso PTA';
            emailSendCadastro.destinatarios = [ usuario.email ];
            emailSendCadastro.corpo = sendEmail.replace('{0}', usuario.nome);

            if (!emailSendCadastro.destinatarios[0].match('@vale')) {
              this.emailService.sendEmail(emailSendCadastro).subscribe(
                _res => {
                  console.log('E-mail enviado!');
                }, error => {
                  console.log(error);
                }
              );
            }

            this.showSucessCreate('Registro criado com sucesso.');
          }
        }

      this.ngOnInit();
    }, error => {
      let erro: number = error || error.messsage;
       this.showErrorCreate(erro);
   });
  }

  update(usuario: Usuario, content) {
    this.open(content);
    //this.updateForm.patchValue(usuario);
    this.updateForm.patchValue({
      id: usuario.id,
      chave: usuario.chave,
      perfil: usuario.perfil,
      nome: usuario.nome,
      status: usuario.status,
      email: usuario.email,
      grupo: usuario.grupo
    });
    this.usuario = usuario;

  }

  onUpdate(updateForm) {
    this.onChange(event, updateForm);
    this.usuario = updateForm.value;
    this.usuarioService.update(this.usuario).subscribe((res) => {
      if (res) {
        if (res.ok === false) {
          this.showErrorUpdate(res.error.message);
        } else {
          this.showSucessUpdate('Registro atualizado com sucesso.');
        }
      }
    }, error => {
      let erro: number = error || error.messsage;
       this.showErrorUpdate(erro);
   });
  }

  onChange(event, updateForm) {
    if (event.target.value) {
       updateForm.perfil = event.target.value;
    }
  }

  onChangeCheckBox(event) {
    if (event.target.checked) {
      this.habilitarMatricula = true;
    } else {
      this.habilitarMatricula = false;
    }
  }
  
  onChangeGrupo(event, updateForm) {
     if (event.target.value) {
        updateForm.grupo = event.target.value;
     }
  }

  delete(usuario, content) {
    this.open(content);
    //this.deleteForm.patchValue(usuario);
    this.deleteForm.patchValue({
      id: usuario.id,
      chave: usuario.chave,
      perfil: usuario.perfil,
      nome: usuario.nome,
      status: usuario.status,
      email: usuario.email,
      grupo: usuario.grupo
    });
    this.usuario = usuario;
  }

  onDelete(deleteForm) {
    this.usuario = JSON.parse(JSON.stringify(deleteForm.value));
    this.usuario.status = 'inativo';
    this.usuarioService.update(this.usuario).subscribe((res) => {
      if (res) {
        if (res.ok === false) {
          this.showErrorUpdate(res.error.message);
        } else {
          this.resetPage();
          this.showSucessUpdate('Usuário excluido com sucesso.');
        }
      }
    }, error => {
      let erro: number = error || error.messsage;
       this.showErrorUpdate(erro);
   });

  //   this.usuarioService.delete(usuario).subscribe((res) => {
  //     this.showSucessDelete('Registro apagado com sucesso.');
  //   }, error => {
  //     let erro :number = error || error.messsage;
  //      this.showErrorDelete(erro);
  //  });
  }

  showSucessCreate(msg: any) {
    this.toastrService.success(msg);
    this.router.navigateByUrl('/usuarios');
    this.getUsuarios();
    this.modalRef.close();
  }

  showSucessUpdate(msg: any) {
    this.toastrService.success(msg);
    this.router.navigateByUrl('/usuarios');
    this.getUsuarios();
    this.modalRef.close();
  }

  showSucessDelete(msg: any) {
    this.toastrService.success(msg);
    this.router.navigateByUrl('/usuarios');
    this.getUsuarios();
    this.modalRef.close();
  }

  showErrorCreate(msg: any) {
    this.toastrService.error(msg);
    this.router.navigateByUrl('/usuarios');
    this.getUsuarios();
    this.modalRef.close();
  }

  showErrorUpdate(msg: any) {
    this.toastrService.error(msg);
    this.router.navigateByUrl('/usuarios');
    this.getUsuarios();
    this.modalRef.close();
  }

  showErrorDelete(msg: any) {
    this.toastrService.error(msg);
    this.router.navigateByUrl('/usuarios');
    this.getUsuarios();
    this.modalRef.close();
  }

  open(content) {
    this.modalRef = this.modalService.open(content);
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  listar(content) {
    this.modalRef = this.modalService.open(content);
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'Pressione ESC para sair';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'Click OK para executar a tarefa';
    } else {
      return `with: ${reason}`;
    }
  }

  createTable() {
    this.dtOptions = {
      "processing": true,
      data: this.usuarios,
      "language": {
        "sEmptyTable": "Nenhum registro encontrado",
        "sInfo": "Mostrando de _START_ até _END_ de _TOTAL_ registros",
        "sInfoEmpty": "Mostrando 0 até 0 de 0 registros",
        "sInfoFiltered": "(Filtrados de _MAX_ registros)",
        "sInfoPostFix": "",
        "sInfoThousands": ".",
        "sLengthMenu": "_MENU_ resultados por página",
        "sLoadingRecords": "Carregando...",
        "sProcessing": "Processando...",
        "sZeroRecords": "Nenhum registro encontrado",
        "sSearch": "Pesquisar",
        "oPaginate": {
            "sNext": "Próximo",
            "sPrevious": "Anterior",
            "sFirst": "Primeiro",
            "sLast": "Último"
        },
        "oAria": {
            "sSortAscending": ": Ordenar colunas de forma ascendente",
            "sSortDescending": ": Ordenar colunas de forma descendente"
        },
        "select": {
            "rows": {
                "_": "Selecionado %d linhas",
                "0": "Nenhuma linha selecionada",
                "1": "Selecionado 1 linha"
            }
        }
    },
      columns: [
          { 'data': 'nome' },
          { "data": 'email' },
          { "data": 'perfil' },
          { "data": 'grupo.nome' },
          { 'data': 'nome', render: function (data, type, row)
            {              
              return '<td *ngIf="!perfilNormativo"> '+
                     '<button type="button" class="btn btn-outline-info btn-rounded waves-effect btn-xs" (click)="update(usuario, mymodalUpdate)">' +
                     '<i class="fa fa-user-circle"></i>Editar</button>' +
                     '</td>';
            }
          },
      ]
    };
    this.dataTable = $(this.table.nativeElement);
    this.dataTable.DataTable(this.dtOptions);
  }

  export() {
    this.usuarioService.exportExcel(this.arrayExport, 'UsuariosPTA');
  }

}
