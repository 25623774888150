import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Mensagem } from '../../models/mensagem.model';
import { MensagemService } from '../mensagem.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

const msgID = environment.message.id;

@Component({
  selector: 'app-mensagem',
  templateUrl: './mensagem.component.html',
  styleUrls: ['./mensagem.component.css']
})
export class MensagemComponent implements OnInit {
  mensagem: Mensagem;
  mensagemForm: FormGroup;
  updateForm: FormGroup;
  deleteForm: FormGroup;
  dataSaved = false;
  title = 'PTA';
  closeResult: string;
  modalOptions: NgbModalOptions;
  modalRef: NgbModalRef;

  constructor(
    private mensagemService: MensagemService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private router: Router) {
  }

  ngOnInit() {
    this.getMensagemPorID(msgID);
    this.updateMensagemForm();
  }

  createForm() {
    this.mensagemForm = this.formBuilder.group({
      titulo: ['', [Validators.required]],
      texto: ['', [Validators.required]],
    });

  }

  getMensagemPorID(msgID: string) {
    this.mensagemService.getMensagemPorID(msgID).subscribe(res => {
      this.mensagem = res.mensagem;
    });
  }

  updateMensagemForm() {
    this.updateForm = this.formBuilder.group({
      id: [''],
      titulo: ['', [Validators.required]],
      texto: ['', [Validators.required]],
    });

  }

  onFormSubmit() {
    this.dataSaved = false;
    this.mensagem = this.mensagemForm.value;
  }

  update(mensagem: Mensagem, updateForm) {
    this.updateForm.patchValue({
      id: mensagem.id,
      titutlo: mensagem.titulo,
      texto: mensagem.texto
    });
    this.onUpdate(updateForm);
  }

  onUpdate(updateForm) {
    let mensagem = JSON.parse(JSON.stringify(this.updateForm.value));
    this.mensagemService.update(mensagem).subscribe((res) => {
      if (res) {
        this.showSucessUpdate('Registro atualizado com sucesso.');
      }
      }, error => {
        let erro : number = error | error.messsage;
        this.showErrorUpdate(erro);
    });
  }

  showSucessUpdate(msg: any) {
    this.toastrService.success(msg);
    this.router.navigateByUrl('/home');
    this.modalRef.close();
  }

  showErrorUpdate(msg: any) {
    this.toastrService.error(msg);
    this.router.navigateByUrl('/home');
    this.modalRef.close();
  }

  open(content) {
    this.modalRef = this.modalService.open(content);
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'Pressione ESC para sair';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'Click OK para executar a tarefa';
    } else {
      return `with: ${reason}`;
    }
  }
}
