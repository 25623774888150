import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Grupo } from '../../models/grupo.model';
import { environment } from 'src/environments/environment';

const APIURL = environment.api_url_pta;
const headers = environment.HttpHeaders;
const httpOptions = { headers };

@Injectable({
  providedIn: 'root'
})
export class GrupoService {

  constructor(private http: HttpClient) { }

  getGrupoPorID(idGrupo: string): Observable<any> {
    return this.http.get<any>(`${APIURL}/grupo/${idGrupo}`, httpOptions).pipe(
      tap(_ => console.log(`Response getProcessos`)),
      catchError(this.handleError<any>('getProcessos', null))
    );
  }

  getGrupos(): Observable<any> {
    return this.http.get<any>(`${APIURL}/grupos`, httpOptions).pipe(
      tap(_ => console.log(`Response grupos`)),
      catchError(this.handleError<any>('getGrupos', null))
    );
  }

  create(grupo: Grupo): Observable<any> {
    return this.http.post<Grupo>(`${APIURL}/grupos`, grupo , httpOptions).pipe(
      tap(_ => console.log(`Response create`)),
      catchError(this.handleError<any>('create', null))
    );
  }

  update(grupo: Grupo) {
    return this.http.put<void>(`${APIURL}/grupos`, JSON.parse(JSON.stringify(grupo)), httpOptions).pipe(
      tap(_ => console.log(`Response update`)),
      catchError(this.handleError<any>('update', null))
    );
  }

  delete(grupo: Grupo) {
    return this.http.delete<Grupo>(`${APIURL}/grupos/${grupo.id}`, httpOptions).pipe(
      tap(_ => console.log(`Response delete`)),
      catchError(this.handleError<Grupo>('delete', null))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      console.log(`${operation} failed: ${error.message}`);
      return of(error);
    };
  }
}
