//import { Anexo } from './anexo.model';

export class Email {
    //remetente: string;
    //nomeRementente: string;
    destinatarios: Array<string>;
    //responderPara: string;
    assunto: string;
    corpo: string;
    //anexos: Array<Anexo>;
}
