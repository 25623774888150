import { BrowserModule } from '@angular/platform-browser';
import { Injectable, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { HttpClientModule, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CKEditorModule } from 'ckeditor4-angular';
import { FullCalendarModule } from '@fullcalendar/angular';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { AdalService } from 'adal-angular4';
import { LoginComponent } from './login/login.component';
import { UploadComponent } from './upload/upload.component';
import { UploaderService } from './upload/upload.service';
import { CadastroComponent } from './cadastro/cadastro.component';
import { UsuarioComponent } from './usuarios/usuario/usuario.component';
import { EmpresaComponent } from './empresa/empresa.component';
import { ProcessoComponent } from './processo/processo.component';
import { RelatorioComponent } from './relatorio/relatorio.component';
import { LoaderComponent } from './shared/loader/loader.component';
import { LoaderService } from './shared/loader/loader.service';
import { LoaderInterceptor } from './shared/loader/loader.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ToastrModule } from 'ngx-toastr';
import { SistemaComponent } from './sistemas/sistema/sistema.component';
import { GrupoComponent } from './grupos/grupo/grupo.component';
import { MensagemComponent } from './mensagens/mensagem/mensagem.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { AuthGuardService } from './guards/auth-guard.service';
import { AuthInterceptor } from './guards/auth-interceptor';
import { AdicionarVirgulaPipe, CalendarioComponent } from './calendario/calendario.component';
import { Util } from './Util/util';
import { ArquivosenviadosComponent } from './arquivosenviados/arquivosenviados.component';

@Injectable()
export class NoCacheHeadersInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const authReq = req.clone({
            setHeaders: {
                'Cache-Control': 'no-cache',
                 Pragma: 'no-cache'
            }
        });
        return next.handle(authReq);    
    }
}

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    LoginComponent,
    UploadComponent,
    CadastroComponent,
    UsuarioComponent,
    EmpresaComponent,
    ProcessoComponent,
    RelatorioComponent,
    LoaderComponent,
    SistemaComponent,
    GrupoComponent,
    MensagemComponent,
    SidebarComponent,
    FooterComponent,
    CalendarioComponent,
    ArquivosenviadosComponent,
    AdicionarVirgulaPipe
   ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgbModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    CKEditorModule,
    ToastrModule.forRoot(),
    FullCalendarModule,
  ],
  providers: [
    Util,
    AdalService,
    UploaderService,
    LoaderService,
    AuthGuardService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NoCacheHeadersInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
