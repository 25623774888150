import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Grupo } from '../../models/grupo.model';
import { GrupoService } from './grupo.service';
import { Empresa } from 'src/app/models/empresa.model';
import { Processo } from 'src/app/models/processo.model';
import { EmpresaService } from 'src/app/empresa/empresa.service';
import { NotificacaoEnvio } from 'src/app/models/notificacaoEnvio.model';
import { ProcessoService } from 'src/app/processo/processo.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Util } from '../../Util/util';


@Component({
  selector: 'app-grupo',
  templateUrl: './grupo.component.html',
  styleUrls: ['./grupo.component.css']
})
export class GrupoComponent implements OnInit {
  grupo: Grupo;
  grupos: Array<Grupo>;
  processos: Array<Processo>;
  processosCompare: Array<Processo>;
  empresas: Array<Empresa>;
  empresasCompare: Array<Empresa>;
  grupoForm: FormGroup;
  updateForm: FormGroup;
  deleteForm: FormGroup;
  dataSaved = false;
  title = 'PTA';
  closeResult: string;
  modalOptions: NgbModalOptions;
  modalRef: NgbModalRef;
  notificacaoEnvio: NotificacaoEnvio[] = [];
  perfilNormativo = false;

  constructor(private grupoService: GrupoService,
    private processoService: ProcessoService,
    private empresaService: EmpresaService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private router: Router,
    private util: Util ) {

    this.createForm();
    this.updateGrupoForm();
    this.deleteGrupoForm();

    this.perfilNormativo = this.util.verificarGrupos();

    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };

  }

  ngOnInit() {
    this.getEmpresas();
    this.getProcessos();
    this.getGrupos();
    this.grupoForm.reset();
  }

  create(grupo: Grupo) {
    this.grupoService.create(grupo).subscribe(
      res => {
        this.dataSaved = true;
        this.ngOnInit();
        if  (res.error === undefined) {
          this.showSucessCreate('Registro criado com sucesso.');
        } else {
          this.showErrorUpdate(res.error);
        }
      }, error => {
        const erro: number = error | error.messsage;
         this.showErrorCreate(erro);
     });
  }

  createForm() {
    this.grupoForm = this.formBuilder.group({
      nome: ['', [Validators.required]],
      // responsavel: this.formBuilder.group({
      //   nome: '',
      //   email: ''
      // }),
      empresas: ['', [Validators.required]],
      periodoEnvio: this.formBuilder.group({
        dataInicio: '',
        dataFim: ''
      }),
      enviarProcessos: ['', [Validators.required]],
      notificacaoEnvio: this.formBuilder.group({
        diasAntes5: '',
        diasAntes3: '',
        diasAntes1: ''
      })
    });

  }

  updateGrupoForm() {
    this.updateForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      nome: ['', [Validators.required]],
      // responsavel: this.formBuilder.group({
      //   nome: '',
      //   email: ''
      // }),
      empresas: ['', [Validators.required]],
      periodoEnvio: this.formBuilder.group({
        dataInicio: '',
        dataFim: ''
      }),
      enviarProcessos: ['', [Validators.required]],
      notificacaoEnvio: this.formBuilder.group({
        diasAntes5: '',
        diasAntes3: '',
        diasAntes1: ''
      })
    });
  }

  deleteGrupoForm() {
    this.deleteForm = this.formBuilder.group({
      id: [{ value: '', disabled: true }],
      nome: [{ value: '', disabled: true }],
      // responsavel: this.formBuilder.group({
      //   nome: '',
      //   email: ''
      // }),
      empresas: [{ value: '', disabled: true }],
      periodoEnvio: [{ value: '', disabled: true }],
      enviarProcessos: [{ value: '', disabled: true }],
      notificacaoEnvio: [{ value: '', disabled: true }],
    });

  }

  onFormSubmit() {
    this.dataSaved = false;
    this.grupo = this.grupoForm.value;
    this.create(this.grupo);
  }

  public getGrupos() {
    this.grupoService.getGrupos().subscribe((res) => {
      this.grupos = res.grupos;
      this.compareEmpresas();
      this.compareProcessos();
   });
  }

  public getEmpresas() {
    this.empresaService.getEmpresas().subscribe((res) => {
      this.empresas = res.empresas;
   });
  }

  public getProcessos() {
    this.processoService.getProcessos().subscribe((res) => {
      this.processos = res.processos;
   });
  }

  /*
    Método que faz a comparação das empresas cadastradas para um determinado grupo e compara com o id cadastrado na collection "empresas"
     *** para recuperar os dados corretos e atualizado.
  */
  public compareEmpresas() {
    if (this.grupos.length > 0 && this.empresas.length > 0) {
      for (let indexGrupos = 0; indexGrupos < this.grupos.length; indexGrupos++) {
        if (this.grupos[indexGrupos].empresas.length > 0) {
          const empresa = this.grupos[indexGrupos].empresas;
          this.empresasCompare = [];
          for (let indexEmpresas = 0; indexEmpresas < empresa.length; indexEmpresas++) {
            // const index = this.empresas.findIndex(val => val.id === empresa[indexEmpresas].id);
            const index = this.empresas.findIndex(val => val.codigo === empresa[indexEmpresas].codigo);
            if (index > -1) {
              // this.empresasCompare.push(this.empresas.find(emp => emp.id === empresa[indexEmpresas].id));
              this.empresasCompare.push(this.empresas.find(emp => emp.codigo === empresa[indexEmpresas].codigo));
            }
          }
          this.grupos[indexGrupos].empresas = this.empresasCompare;
        }
      }
    }
  }

  /*
    Método que faz a comparação dos processos cadastrados para um determinado grupo e compara com o id cadastrado na collection "processos"
     *** para recuperar os dados corretos e atualizado.
  */
  public compareProcessos() {
    if (this.grupos.length > 0 && this.processos.length > 0) {
      for (let indexGrupos = 0; indexGrupos < this.grupos.length; indexGrupos++) {
        if (this.grupos[indexGrupos].enviarProcessos.length > 0) {
          const processo = this.grupos[indexGrupos].enviarProcessos;
          this.processosCompare = [];
          for (let indexProcessos = 0; indexProcessos < processo.length; indexProcessos++) {
            const index = this.processos.findIndex(val => val.id === processo[indexProcessos].id);
            if (index > -1) {
              this.processosCompare.push(this.processos.find(proc => proc.id === processo[indexProcessos].id));
            }
          }
          this.grupos[indexGrupos].enviarProcessos = this.processosCompare;
        }
      }
    }
  }

  update(grupo: Grupo, content) {
    const emp = grupo;
    this.open(content);
    this.updateForm.patchValue({
      id: grupo.id,
      nome: grupo.nome,
      // responsavel: grupo.responsavel,
      // periodoEnvio: grupo.periodoEnvio,
      enviarProcessos: grupo.enviarProcessos,
      empresas: grupo.empresas,
      notificacaoEnvio: grupo.notificacaoEnvio
    });
  }

  onUpdate(updateForm) {
    const grupo = JSON.parse(JSON.stringify(this.updateForm.value));
    this.grupoService.update(grupo).subscribe((res) => {
      this.showSucessUpdate('Registro atualizado com sucesso.');
      }, error => {
        const erro: number = error | error.messsage;
         this.showErrorUpdate(erro);
     });

  }

  delete(grupo, content) {
    this.open(content);
    this.deleteForm.patchValue(grupo);
  }

  onDelete(deleteForm) {
    const deleteGrupo = JSON.parse(JSON.stringify(deleteForm.value));
    this.grupoService.delete(deleteGrupo).subscribe((res) => {
      this.showSucessDelete('Registro apagado com sucesso.');
    }, error => {
      const erro: number = error | error.messsage;
       this.showErrorDelete(erro);
   });
  }

  showSucessCreate(msg: any) {
    this.toastrService.success(msg);
    this.router.navigateByUrl('/grupos');
    this.getGrupos();
    this.modalRef.close();
  }

  showSucessUpdate(msg: any) {
    this.toastrService.success(msg);
    this.router.navigateByUrl('/grupos');
    this.getGrupos();
    this.modalRef.close();
  }

  showSucessDelete(msg: any) {
    this.toastrService.success(msg);
    this.router.navigateByUrl('/grupos');
    this.getGrupos();
    this.modalRef.close();
  }

  showErrorCreate(msg: any) {
    this.toastrService.error(msg);
    this.router.navigateByUrl('/grupos');
    this.getGrupos();
    this.modalRef.close();
  }

  showErrorUpdate(msg: any) {
    this.toastrService.error(msg);
    this.router.navigateByUrl('/grupos');
    this.getGrupos();
    this.modalRef.close();
  }

  showErrorDelete(msg: any) {
    this.toastrService.error(msg);
    this.router.navigateByUrl('/grupos');
    this.getGrupos();
    this.modalRef.close();
  }

  open(content) {
    this.modalRef = this.modalService.open(content);
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'Pressione ESC para sair';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'Click OK para executar a tarefa';
    } else {
      return `with: ${reason}`;
    }
  }
}
