import { Component, OnInit } from '@angular/core';
import { AdalService } from 'adal-angular4';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private adal: AdalService, private router: Router) {
    if (!this.adal.userInfo.authenticated) {
      this.adal.login();
    } else {
      this.router.navigate(['/home']);
    }
  }

  logout() {
    localStorage.removeItem('token');
  }
  loggedIn(): boolean {
    return localStorage.getItem('token') !==  null;
  }

  ngOnInit() {
  }
}
