import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AdalService } from 'adal-angular4';
import * as jwt_decode from 'jwt-decode';
import { AuthCriptografiaService } from './auth-criptografia.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private router: Router,
    private adal: AdalService,
    private authCriptografiaService: AuthCriptografiaService) { }
    private isAdminGroup = false;

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.adal.userInfo.authenticated) {
        localStorage.setItem('token',
        this.authCriptografiaService.encryptData(this.adal.userInfo.token, this.adal.userInfo.token));
        const token = this.getDecodedAccessToken(this.adal.userInfo.token);
        // console.log('GRUPO' + token);
        //return this.isAdminGroup = this.adal.userInfo.authenticated;
        if (token.groups) {
          // console.log(JSON.stringify(token.groups.length));
          return this.isAdminGroup = this.adal.userInfo.authenticated;
        } else {
           console.log('Variavel isAdminGroup (arquivo auth-guard.service.ts) ' + this.isAdminGroup);
           return this.isAdminGroup;
        }
     } else {
      this.adal.login();
     }
}

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
        return null;
    }
  }
}
