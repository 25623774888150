import { Injectable } from '@angular/core';
import { RegistroAtividade } from '../models/registro-atividade.model';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpHeaders, HttpClient, HttpEventType } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const APIURL = environment.api_url_pta;
const APICONSULTA = environment.api_url_post_consulta;
const APICONSULTASDK = environment.api_url_post_consultaSDK;
const URL = 'http://localhost:7071/api/exportexcel';
const HttpOptions = {
  headers: new HttpHeaders({
    'Accept':  'application/json',
    'Content-Type':  'application/json',
    'Access-Control-Allow-Origin': '*',
  })
};
const headers = environment.HttpHeaders;

const httpOptions = { headers };
@Injectable({
  providedIn: 'root'
})
export class ArquivosenviadosService {

  constructor(private http: HttpClient) { }

  createRegistroAtividade(registro: RegistroAtividade): Observable<any> {
    return this.http.post<RegistroAtividade>(`${APIURL}/registroatividade`, JSON.stringify(registro) ,httpOptions).pipe(
      tap(_ => console.log(`Response createRegistroAtividade`)),
      catchError(this.handleError<any>('createRegistroAtividade', null))
    );
  }

  getRegistroAtividade(): Observable<any> {
    return this.http.get<any>(`${APIURL}/registroatividades`, httpOptions).pipe(
      tap(_ => console.log(`Response getRegistroAtividade`)),
      catchError(this.handleError<any>('getRegistroAtividade', null))
    );
  }

  getRegistroAtividadePorUsuario(query: any): Observable<any> {
    return this.http.post<any>(`${APICONSULTA}/pta/colecoes/registro-atividades`, JSON.stringify(query), httpOptions).pipe(
      tap(_ => console.log(`Response getRegistroAtividadePorUsuario`)),
      catchError(this.handleError<any>('getRegistroAtividadePorUsuario', null))
    );
  }

  getRegistroAtividadePorUsuarioSDK(query: any): Observable<any> {
    return this.http.post<any>(`${APICONSULTASDK}/pta/colecoes/registro-atividades`, JSON.stringify(query), httpOptions).pipe(
      tap(_ => console.log(`Response getRegistroAtividadePorUsuario`)),
      catchError(this.handleError<any>('getRegistroAtividadePorUsuario', null))
    );
  }

  getDownload(registro: any): Observable<any> {
    return this.http.post<any>(`${URL}`, registro, HttpOptions).pipe(
      tap(_ => console.log(`Response getDownload`)),
      catchError(this.handleError<any>('getDownload', null))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
