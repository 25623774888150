import { Component, OnInit } from '@angular/core';
import { EmpresaService } from './empresa.service';
import { Empresa } from '../models/empresa.model';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Util } from '../Util/util';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.css']
})
export class EmpresaComponent implements OnInit {
  empresa: Empresa;
  empresas: Array<Empresa> = [];
  dataSaved = false;
  empresaForm: FormGroup;
  updateForm: FormGroup;
  deleteForm: FormGroup;
  title = 'PTA';
  closeResult: string;
  modalOptions: NgbModalOptions;
  modalRef: NgbModalRef;
  tipoEmpresa = ['Patrocinadora', 'Consignataria'];
  perfilNormativo = false;

  constructor(
    private empresaService: EmpresaService,
    private formbuilder: FormBuilder,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private router: Router,
    private util: Util) {
    this.getEmpresas();
    this.createForm();
    this.updateEmpresaForm();
    this.deleteEmpresaForm();
    this.perfilNormativo = this.util.verificarGrupos();
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit() {
    this.empresaForm.reset();
  }

  createForm() {
    this.empresaForm = this.formbuilder.group({
      codigo: ['', [Validators.required]],
      //nome_abreviado: ['', [Validators.required]],
      nome_completo: ['', [Validators.required]],
      tipo: ['', [Validators.required]]
    });
  }

  updateEmpresaForm() {
    this.updateForm = this.formbuilder.group({
      id: [''],
      codigo: [{ disabled: true }],
      //nome_abreviado: ['', [Validators.required]],
      nome_completo: ['', [Validators.required]],
      tipo: ['', [Validators.required]]
    });
  }

  deleteEmpresaForm() {
    this.deleteForm = this.formbuilder.group({
      id: [{value: '', disabled: true}],
      codigo: [{ value: '', disabled: true }],
      //nome_abreviado: [{ value: '', disabled: true }],
      nome_completo: [{ value: '', disabled: true }],
      tipo: [{ value: '', disabled: true }]
    });
  }

  onFormSubmit() {
    this.dataSaved = false;
    let empresa = this.empresaForm.value;
    this.create(this.empresa);
    this.empresaForm.reset();
  }

  public getEmpresaPorID(empresaID: string) {
    this.empresaService.getEmpresaPorID(empresaID).subscribe(res => {
      this.empresa = res.empresa;
      console.log(res);
    });
  }

  public getEmpresas() {
    this.empresaService.getEmpresas().subscribe((res) => {
      this.empresas = res.empresas;
    });
  }

  create(empresa: Empresa) {
    this.empresaService.create(empresa).subscribe(
      res => {
        this.dataSaved = true;
        this.getEmpresas();
        this.empresaForm.reset();
        if  (res.statusText === 'Unknown Error') {
          this.showErrorUpdate('Erro ao cadastrar empresa.');
        } else if (res.statusText === 'Access Denied') {
          this.showErrorUpdate('Erro ao cadastrar empresa.');
        } else {
          this.showSucessCreate('Registro criado com sucesso.');
        }
      }, error => {
        let erro: any = error || error.message;
        this.showErrorCreate(erro);
      }
    );
  }

  update(empresa: Empresa, content) {
    this.open(content);
    this.updateForm.patchValue({
      id: empresa.id,
      codigo: empresa.codigo,
      //nome_abreviado: empresa.nome_abreviado,
      nome_completo: empresa.nome_completo,
      tipo: empresa.tipo
    });
    this.empresa = empresa;
  }

  onUpdate(updateForm) {
    this.onChange(event, this.updateForm);
    let empUpdate = JSON.parse(JSON.stringify(this.updateForm.value));
    this.empresaService.update(empUpdate).subscribe((res) => {
    if (res) {
      this.showSucessUpdate('Registro atualizado com sucesso.');
    }
    }, error => {
      let erro: any = error || error.message;
      this.showErrorUpdate(erro);
    });

  }

  onChange(event, updateForm) {
    if (event.target.value) {
       updateForm.tipo = event.target.value;
    }
 }

  delete(empresa, content) {
    this.open(content);
    this.deleteForm.patchValue(empresa);
  }

  onDelete(deleteForm) {
    let empresaDelete = JSON.parse(JSON.stringify(deleteForm.value));
    this.getEmpresaPorID(empresaDelete.codigo);
    this.empresaService.delete(empresaDelete.codigo).subscribe((res) => {
      this.showSucessDelete('Registro apagado com sucesso.');
    }, error => {
      let erro: number = error || error.messsage;
       this.showErrorDelete(erro);
   });
  }


showSucessCreate(msg: any) {
  this.toastrService.success(msg);
  this.router.navigateByUrl('/empresa');
  this.getEmpresas();
  this.modalRef.close();
}

showSucessUpdate(msg: any) {
  this.toastrService.success(msg);
  this.router.navigateByUrl('/empresa');
  this.getEmpresas();
  this.modalRef.close();
}

showSucessDelete(msg: any) {
  this.toastrService.success(msg);
  this.router.navigateByUrl('/empresa');
  this.getEmpresas();
  this.modalRef.close();
}

showErrorCreate(msg: any) {
  this.toastrService.error(msg);
  this.router.navigateByUrl('/empresa');
  this.getEmpresas();
  this.modalRef.close();
}

showErrorUpdate(erro: any) {
  this.toastrService.error(erro);
  this.modalRef.close();
  this.router.navigateByUrl('/empresa');
  this.getEmpresas();
}

showErrorDelete(msg: any) {
  this.toastrService.error(msg);
  this.router.navigateByUrl('/empresa');
  this.getEmpresas();
  this.modalRef.close();
}

open(content) {
    this.modalRef = this.modalService.open(content);
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
}

private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'Pressione ESC para sair';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'Click OK para executar a tarefa';
    } else {
      return `with: ${reason}`;
    }
}

}
