import {
  Component,
  Injectable,
  Pipe,
  OnInit,
  PipeTransform,
} from "@angular/core";
import { RegistroAtividade } from "../models/registro-atividade.model";
import { TipoAtividade } from "../models/tipo-atividade";
import { TipoPerfil } from "../models/tipo-perfil";

import * as moment from "moment";

import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { UsuarioService } from "../usuarios/usuario/usuario.service";
import { AdalService } from "adal-angular4";
import {
  NgbModal,
  ModalDismissReasons,
  NgbModalRef,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbDate,
  NgbDatepickerI18n,
} from "@ng-bootstrap/ng-bootstrap";
import { formatDate } from "@angular/common";
import { CalendarioService } from "./calendario.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = "-";

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date
      ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year
      : null;
  }
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = "/";

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year
      : "";
  }
}

@Injectable()
export class I18n {
  language = "pt";
}

const I18N_VALUES = {
  pt: {
    weekdays: ["Seg", "Ter", "Qua", "Qui", "Sex", "Sab", "Dom"],
    months: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    weekLabel: "sem",
  },
};

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getWeekLabel(): string {
    return I18N_VALUES[this._i18n.language].weekLabel;
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@Pipe({ name: "adicionarVirgula" })
export class AdicionarVirgulaPipe implements PipeTransform {
  transform(value: string): string {
    return value && value != "" ? ", " + value : "";
  }
}

@Component({
  selector: "app-calendario",
  templateUrl: "./calendario.component.html",
  styleUrls: ["./calendario.component.scss"],
  providers: [
    I18n,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
  ],
})
export class CalendarioComponent implements OnInit {
  calendarios: Array<RegistroAtividade> = [];
  calendariosFilter: Array<RegistroAtividade> = [];
  calendario: RegistroAtividade;
  registroAtividades: Array<RegistroAtividade>;

  tiposPerfilFilter = []; //'Todos', 'Consignatária', 'Patrocinadora'
  anosReferenciaFilter = [];

  usuario: any;
  userEmail: any;

  calendarioForm: FormGroup;
  updateDataPatrocinadora: FormGroup;
  updateForm: FormGroup;
  modalRef: NgbModalRef;
  closeResult: string;
  selectedAnoReferencia: string;
  selectedMesReferencia: string;
  selectedTipoPerfil: string;
  selectedAnoEdicao: string;

  tiposPerfil = [TipoPerfil.consignataria, TipoPerfil.patrocinadora];
  mesReferencia = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  disablebutton: boolean = true;

  markDisabled;
  displayMonths = 1;
  showWeekNumbers = false;
  mesNotificacao = "";

  date = new Date();
  //currentYearCalendar = 2023;
  currentYearCalendar = this.date.getFullYear();
  currentMonthCalendar = this.date.getMonth() + 1;
  currentDayCalendar = this.date.getDate();

  nextYearCalendar = this.currentYearCalendar + 1;
  lastYearCalendar = this.currentYearCalendar - 1;

  constructor(
    private calendarioService: CalendarioService,
    private formBuilder: FormBuilder,
    private adal: AdalService,
    private usuarioService: UsuarioService,
    private toastrService: ToastrService,
    private router: Router,
    private dateAdapter: NgbDateAdapter<string>,
    private modalService: NgbModal
  ) {
    this.getTipoPerfil();
    this.getAnoReferencia();
    this.getCalendarios(this.selectedAnoReferencia, this.selectedTipoPerfil);
    this.createCalendarioForm();
    this.updateCalendarioForm();
  }

  ngOnInit() {
    this.getEmailUser();
  }

  adicionaZero(numero) {
    if (numero <= 9) return "0" + numero;
    else return numero;
  }

  updateDisabledDates() {
    this.markDisabled = (date: NgbDate, current: { month: number }) =>
      new Date(date.year, date.month - 1, date.day).getDay() === 6 ||
      new Date(date.year, date.month - 1, date.day).getDay() === 0;
  }

  rulesDisabledCreate(calendario) {
    let disabledCreate = false;
    if (calendario.dataNotificacao1) {
      return (disabledCreate = true);
    } else {
      var dataAtual = new Date();
      var dataFormatadaAtual = new Date(
        dataAtual.getFullYear() +
          "/" +
          this.adicionaZero(dataAtual.getMonth() + 1)
      );
      var dataReferenciaNotificacao = calendario.mesReferenciaNotificacao;
      var dataReferenciaNotificacaoDate = new Date(
        dataReferenciaNotificacao.split("/").reverse().join("/")
      );

      if (dataFormatadaAtual > dataReferenciaNotificacaoDate) {
        return (disabledCreate = true);
      } else {
        return (disabledCreate = false);
      }
    }
  }

  rulesDisabledEdit(calendario) {
    let disabledEdit = false;
    if (calendario.tipoPerfil == "Patrocinadora") {
      return (disabledEdit = true);
    } else if (
      calendario.tipoPerfil == "Consignatária" &&
      calendario.dataNotificacao1 == null
    ) {
      return (disabledEdit = true);
    } else if (
      calendario.tipoPerfil == "Consignatária" &&
      calendario.dataNotificacao1 != null &&
      calendario.mesNotificacao != null
    ) {
      var dataNotificacao =
        calendario.dataNotificacao1 + "/" + calendario.mesNotificacao;
      var dataNotificacaoFormatado = new Date(
        dataNotificacao.split("/").reverse().join("/")
      );

      var dataAtual = new Date();

      if (dataAtual > dataNotificacaoFormatado) {
        return (disabledEdit = true);
      } else {
        return (disabledEdit = false);
      }
    }
  }

  minDateCalendar(mesReferenciaNotificacao, tipoPerfil) {
    console.log(mesReferenciaNotificacao, tipoPerfil);
    if (parseInt(this.selectedAnoReferencia) === this.currentYearCalendar) {
      if (
        mesReferenciaNotificacao ==
        this.adicionaZero(this.currentMonthCalendar) +
          "/" +
          this.currentYearCalendar
      ) {
        return {
          year: this.currentYearCalendar,
          month: this.currentMonthCalendar,
          day: this.currentDayCalendar + 1,
        };
      } else if (tipoPerfil === "Consignatária") {
        if (mesReferenciaNotificacao == "12/" + this.currentYearCalendar) {
          return {
            year: this.currentYearCalendar,
            month: 11,
            day: 1,
          };
        } else if (
          mesReferenciaNotificacao ==
          "01/" + this.currentYearCalendar
        ) {
          return {
            year: this.lastYearCalendar,
            month: 12,
            day: 1,
          };
        } else if (
          mesReferenciaNotificacao ==
          "02/" + this.currentYearCalendar
        ) {
          return {
            year: this.currentYearCalendar,
            month: 1,
            day: 1,
          };
        } else {
          return {
            year: this.currentYearCalendar,
            month: 1,
            day: 1,
          };
        }
      } else {
        return {
          year: this.currentYearCalendar,
          month: 1,
          day: 1,
        };
      }
    } else if (parseInt(this.selectedAnoReferencia) === this.nextYearCalendar) {
      if (mesReferenciaNotificacao == "12/" + this.nextYearCalendar) {
        return {
          year: this.nextYearCalendar,
          month: 11,
          day: 1,
        };
      } else if (mesReferenciaNotificacao == "01/" + this.nextYearCalendar) {
        return {
          year: this.currentYearCalendar,
          month: 12,
          day: 1,
        };
      } else if (mesReferenciaNotificacao == "01/" + this.nextYearCalendar) {
        return {
          year: this.nextYearCalendar,
          month: 1,
          day: 1,
        };
      } else {
        return {
          year: this.nextYearCalendar,
          month: 1,
          day: 1,
        };
      }
    } else {
      return {
        year: this.nextYearCalendar,
        month: 1,
        day: 1,
      };
    }
  }

  maxDateCalendar(mesReferenciaNotificacao, tipoPerfil) {
    if (parseInt(this.selectedAnoReferencia) === this.currentYearCalendar) {
      if (tipoPerfil === "Consignatária") {
        if (mesReferenciaNotificacao == "12/" + this.currentYearCalendar) {
          return {
            year: this.currentYearCalendar,
            month: 12,
            day: 31,
          };
        } else if (
          mesReferenciaNotificacao ==
          "01/" + this.currentYearCalendar
        ) {
          return {
            year: this.currentYearCalendar,
            month: 1,
            day: 31,
          };
        } else if (
          mesReferenciaNotificacao ==
          "02/" + this.currentYearCalendar
        ) {
          return {
            year: this.currentYearCalendar,
            month: 2,
            day: 28,
          };
        } else {
          return {
            year: this.currentYearCalendar,
            month: 12,
            day: 31,
          };
        }
      } else {
        return {
          year: this.currentYearCalendar,
          month: 12,
          day: 31,
        };
      }
    } else if (parseInt(this.selectedAnoReferencia) === this.nextYearCalendar) {
      if (mesReferenciaNotificacao == "12/" + this.nextYearCalendar) {
        return {
          year: this.nextYearCalendar,
          month: 12,
          day: 31,
        };
      } else if (mesReferenciaNotificacao == "01/" + this.nextYearCalendar) {
        return {
          year: this.nextYearCalendar,
          month: 1,
          day: 31,
        };
      } else if (mesReferenciaNotificacao == "02/" + this.nextYearCalendar) {
        return {
          year: this.nextYearCalendar,
          month: 2,
          day: 28,
        };
      } else {
        return {
          year: this.nextYearCalendar,
          month: 12,
          day: 31,
        };
      }
    } else {
      return {
        year: this.nextYearCalendar,
        month: 12,
        day: 31,
      };
    }
  }

  navigationMesesCalendar(mesReferenciaNotificacao, tipoPerfil) {
    let navigation = "";
    if (tipoPerfil == "Patrocinadora") {
      return (navigation = "none");
    } else {
      if (
        mesReferenciaNotificacao == "12/" + this.currentYearCalendar ||
        mesReferenciaNotificacao == "12/" + this.nextYearCalendar ||
        mesReferenciaNotificacao == "01/" + this.nextYearCalendar ||
        mesReferenciaNotificacao == "01/" + this.currentYearCalendar ||
        mesReferenciaNotificacao == "02/" + this.nextYearCalendar ||
        mesReferenciaNotificacao == "02/" + this.currentYearCalendar
      ) {
        return (navigation = "arrow");
      } else {
        return (navigation = "none");
      }
    }
  }

  outsideDaysCalendar(mesReferenciaNotificacao, tipoPerfil) {
    let outsideDays = "";
    if (tipoPerfil == "Patrocinadora") {
      return (outsideDays = "hidden");
    } else {
      if (
        mesReferenciaNotificacao == "12/" + this.currentYearCalendar ||
        mesReferenciaNotificacao == "12/" + this.nextYearCalendar ||
        mesReferenciaNotificacao == "01/" + this.nextYearCalendar ||
        mesReferenciaNotificacao == "01/" + this.currentYearCalendar ||
        mesReferenciaNotificacao == "02/" + this.nextYearCalendar ||
        mesReferenciaNotificacao == "02/" + this.currentYearCalendar
      ) {
        return (outsideDays = "visible");
      } else {
        return (outsideDays = "hidden");
      }
    }
  }

  getEmailUser() {
    if (this.adal.userInfo.authenticated) {
      this.usuario = this.adal.userInfo;
      this.usuarioService
        .getEmailUser(this.usuario.profile.email)
        .subscribe((res) => {
          this.userEmail = res.usuario;
        });
    }
  }

  subtractDays(qtdDaysRemove, dateToRemove): number {
    let saturday;
    let sunday;

    let initialMonth = formatDate(new Date(dateToRemove), "MM", "en");
    let daysRemaining = qtdDaysRemove;

    let finalDay = 0;

    let processedDate = moment(new Date(dateToRemove));

    while (daysRemaining > 0) {
      processedDate = processedDate.add(-1, "day");

      saturday = processedDate.day() === 6;
      sunday = processedDate.day() === 0;

      if (!saturday && !sunday) {
        daysRemaining--;
      }
    }

    finalDay = Number(processedDate.format("D"));

    if (initialMonth != processedDate.format("MM")) {
      finalDay = 0;
    }
    return finalDay;
  }

  calculateNotificationDays(form, dataSelecionada) {
    const data = moment(
      new Date(
        dataSelecionada._model.year +
          "-" +
          dataSelecionada._model.month +
          "-" +
          dataSelecionada._model.day
      )
    );

    const data_5 = this.subtractDays(4, data);
    const data_10 = this.subtractDays(9, data);
    // const data_5 = this.subtractDays(2, data);
    // const data_10 = this.subtractDays(4, data);

    let datas;
    let diaLimite = dataSelecionada._model.day;

    if (form.value.tipoPerfil === TipoPerfil.patrocinadora) {
      datas =
        (data_10 != 0 ? `${data_10}, ` : "") +
        (data_5 != 0 ? `${data_5}, ` : "") +
        diaLimite;
    } else if (form.value.tipoPerfil === TipoPerfil.consignataria) {
      datas = diaLimite;
      this.mesNotificacao =
        ("0" + dataSelecionada._model.month).slice(-2) +
        "/" +
        dataSelecionada._model.year;
    }

    this.disablebutton = false;

    this.calendarioForm.patchValue({
      datasEnvio: datas.toString(),
      mesNotificacao: this.mesNotificacao,
    });

    this.updateForm.patchValue({
      dataNotificacao1: datas.toString(),
      mesNotificacao: this.mesNotificacao,
    });
  }

  today(comp) {
    comp.toggle();
    comp.navigateTo({
      year: Number(this.selectedAnoReferencia),
      month: Number(this.selectedMesReferencia),
    });
  }

  getTipoPerfil() {
    this.tiposPerfilFilter = [];

    this.tiposPerfilFilter.push("Todos");
    this.tiposPerfilFilter.push(TipoPerfil.consignataria);
    this.tiposPerfilFilter.push(TipoPerfil.patrocinadora);

    this.selectedTipoPerfil = "Todos";
  }

  getAnoReferencia() {
    this.anosReferenciaFilter = [];

    for (
      let ano = this.currentYearCalendar;
      ano <= Number(this.nextYearCalendar);
      ano++
    ) {
      this.anosReferenciaFilter.push(ano);
    }

    this.selectedAnoReferencia =
      this.selectedAnoEdicao == undefined
        ? this.currentYearCalendar.toString()
        : this.selectedAnoEdicao;
  }

  onChangeAnoReferencia(newValue) {
    this.selectedAnoReferencia = newValue;

    this.getCalendarios(this.selectedAnoReferencia, this.selectedTipoPerfil);
  }

  onChangeTipoPerfil(newValue) {
    // console.log("tipo " + newValue);
    // console.log("ano " + this.selectedAnoReferencia);
    this.selectedTipoPerfil = newValue;

    this.getCalendarios(this.selectedAnoReferencia, this.selectedTipoPerfil);
  }

  getCalendarios(anoReferencia, tipoPerfil) {
    this.calendarioService
      .getEnvioNotificacao(anoReferencia)
      .subscribe((res) => {
        this.calendarios = res.Documents;
        // console.log("calendarios component antes ", this.calendarios);
        this.calendarios = this.completarCalendario(
          this.calendarios,
          tipoPerfil
        );
        this.calendariosFilter = this.calendarios;
      });
  }

  completarCalendario(calendario: Array<RegistroAtividade>, perfil) {
    let mesString: string = "";
    let tiposPerfilFilterComplete = [];

    if (perfil === "Todos") {
      tiposPerfilFilterComplete.push(TipoPerfil.consignataria);
      tiposPerfilFilterComplete.push(TipoPerfil.patrocinadora);
    } else if (perfil === TipoPerfil.consignataria) {
      tiposPerfilFilterComplete.push(TipoPerfil.consignataria);
      calendario = calendario.filter((item) => item.tipoPerfil === perfil);
    } else if (perfil === TipoPerfil.patrocinadora) {
      tiposPerfilFilterComplete.push(TipoPerfil.patrocinadora);
      calendario = calendario.filter((item) => item.tipoPerfil === perfil);
    }

    for (var mes of this.mesReferencia) {
      mesString = mes + "/" + this.selectedAnoReferencia;

      for (var itemPerfil of tiposPerfilFilterComplete) {
        if (
          !calendario.find(
            ({ mesReferenciaNotificacao, tipoPerfil }) =>
              mesReferenciaNotificacao === mesString &&
              tipoPerfil === itemPerfil
          )
        ) {
          let objCalendario: RegistroAtividade = new RegistroAtividade();

          objCalendario.mesReferenciaNotificacao = mesString;
          objCalendario.tipoPerfil = itemPerfil;

          calendario.push(objCalendario);
        }
      }
    }

    calendario.sort((a, b) =>
      a.mesReferenciaNotificacao.localeCompare(b.mesReferenciaNotificacao)
    );
    return calendario;
  }

  createCalendarioForm() {
    this.calendarioForm = this.formBuilder.group({
      id: [""],
      mesReferenciaNotificacao: [""],
      mesNotificacao: [""],
      tipoPerfil: [""],
      diaLimite: ["", [Validators.required]],
      datasEnvio: [""],
    });
  }

  createCalendario(form) {
    let registro: RegistroAtividade = new RegistroAtividade();
    registro.tipo = TipoAtividade.envioNotificacao;
    registro.dataHora = new Date();
    registro.usuario = this.usuario.profile.name;
    registro.mesReferenciaNotificacao =
      this.calendarioForm.value.mesReferenciaNotificacao;

    if (this.calendarioForm.value.tipoPerfil === "Consignatária") {
      registro.dataNotificacao1 = this.calendarioForm.value.datasEnvio
        .toString()
        .trim();
      registro.mesNotificacao = this.calendarioForm.value.mesNotificacao
        .toString()
        .trim();
    } else {
      const diasEnvio = this.calendarioForm.value.datasEnvio.split(",");

      registro.dataNotificacao1 =
        diasEnvio.length >= 1 ? diasEnvio[0].trim() : "";
      registro.dataNotificacao2 =
        diasEnvio.length >= 2 ? diasEnvio[1].trim() : "";
      registro.dataNotificacao3 =
        diasEnvio.length >= 3 ? diasEnvio[2].trim() : "";
    }

    registro.tipoPerfil = this.calendarioForm.value.tipoPerfil;

    // console.log("registro atividade montado ", registro);

    this.calendarioService.createEnvioNotificacao(registro).subscribe(
      (res) => {
        // console.log(res);
        this.showSuccessCreate(
          "Dias de envio notificação definido com sucesso."
        );
      },
      (error) => {
        const erro: number = error || error.messsage;
        this.showErrorCreate(erro);
      }
    );
    this.selectedAnoEdicao =
      this.calendarioForm.value.mesReferenciaNotificacao.slice(3, 7);
  }

  createEnvio(calendario: RegistroAtividade, content): void {
    this.selectedMesReferencia =
      calendario.mesReferenciaNotificacao.split("/")[0];

    // console.log("ano ", this.selectedAnoReferencia);
    // console.log("mes ", this.selectedMesReferencia);
    this.dateAdapter.toModel(
      new NgbDate(
        Number(this.selectedAnoReferencia),
        Number(this.selectedMesReferencia),
        1
      )
    )!;

    this.updateDisabledDates();

    this.open(content);

    this.calendarioForm.patchValue({
      id: calendario.id,
      mesReferenciaNotificacao: calendario.mesReferenciaNotificacao,
      mesNotificacao: calendario.mesNotificacao,
      tipoPerfil: calendario.tipoPerfil,
      diaLimite: calendario.dataNotificacao1,
      datasEnvio:
        (calendario.dataNotificacao1 != undefined
          ? calendario.dataNotificacao1
          : "") +
        " " +
        (calendario.dataNotificacao2 != undefined
          ? calendario.dataNotificacao2
          : "") +
        " " +
        (calendario.dataNotificacao3 != undefined
          ? calendario.dataNotificacao3
          : ""),
    });
  }

  updateCalendarioForm() {
    this.updateForm = this.formBuilder.group({
      id: [""],
      tipo: [""],
      dataHora: [""],
      usuario: [""],
      mesReferenciaNotificacao: [{ disabled: true }],
      dataNotificacao1: [""],
      mesNotificacao: [""],
      tipoPerfil: [""],
    });
  }

  updateEnvioNotificacaoForm(calendario: RegistroAtividade, content): void {
    this.selectedMesReferencia =
      calendario.mesReferenciaNotificacao.split("/")[0];
    // console.log("ano ", this.selectedAnoReferencia);
    // console.log("mes ", this.selectedMesReferencia);
    this.dateAdapter.toModel(
      new NgbDate(
        Number(this.selectedAnoReferencia),
        Number(this.selectedMesReferencia),
        1
      )
    )!;

    this.open(content);
    this.updateForm.patchValue({
      id: calendario.id,
      tipo: "EnvioNotificacao",
      dataHora: new Date(),
      usuario: this.usuario.profile.name,
      mesReferenciaNotificacao: calendario.mesReferenciaNotificacao,
      mesNotificacao: calendario.mesNotificacao,
      tipoPerfil: calendario.tipoPerfil,
      dataNotificacao1: calendario.dataNotificacao1,
    });
  }

  onUpdate(updateEnvioNotificacaoForm) {
    this.onChange(event, this.updateForm);
    let calendar = JSON.parse(JSON.stringify(this.updateForm.value));
    this.calendarioService.update(calendar).subscribe(
      (res) => {
        if (res) {
          this.showSuccessUpdate("Registro atualizado com sucesso.");
        }
      },
      (error) => {
        let erro: any = error || error.message;
        this.showErrorUpdate(erro);
      }
    );
  }

  onChange(event, updateForm) {
    if (event.target.value) {
      updateForm.tipo = event.target.value;
    }
  }

  open(content) {
    this.disablebutton = true;

    this.modalRef = this.modalService.open(content);
    this.modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "Pressione ESC para sair";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "Click OK para executar a tarefa";
    } else {
      return `with: ${reason}`;
    }
  }

  showSuccessCreate(msg: any) {
    this.toastrService.success(msg);
    this.modalRef.close();
    this.router.navigateByUrl("/calendario");

    this.getTipoPerfil();
    this.getAnoReferencia();

    this.getCalendarios(this.selectedAnoReferencia, this.selectedTipoPerfil);

    this.createCalendarioForm();
  }

  showSuccessUpdate(msg: any) {
    this.toastrService.success(msg);
    this.modalRef.close();
    this.router.navigateByUrl("/calendario");

    this.getTipoPerfil();
    this.getAnoReferencia();

    this.getCalendarios(this.selectedAnoReferencia, this.selectedTipoPerfil);

    this.updateCalendarioForm();
  }

  showErrorCreate(msg: any) {
    this.toastrService.error(msg);
    this.modalRef.close();
    this.router.navigateByUrl("/calendario");

    this.getTipoPerfil();
    this.getAnoReferencia();

    this.getCalendarios(this.selectedAnoReferencia, this.selectedTipoPerfil);

    this.createCalendarioForm();
  }

  showErrorUpdate(msg: any) {
    this.toastrService.error(msg);
    this.modalRef.close();
    this.router.navigateByUrl("/calendario");

    this.getTipoPerfil();
    this.getAnoReferencia();

    this.getCalendarios(this.selectedAnoReferencia, this.selectedTipoPerfil);

    this.updateCalendarioForm();
  }
}
