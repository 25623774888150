import { Injectable } from '@angular/core';
import {
  HttpClient, HttpHeaders
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Usuario } from '../../models/usuario.model';
import { environment } from 'src/environments/environment';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const APIURL = environment.api_url_pta;
const headers = environment.HttpHeaders;
const httpOptions = { headers };

@Injectable({
  providedIn: 'root'
})

export class UsuarioService {
  constructor(private http: HttpClient) { }

  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';

  getEmailUser(emailUser: string): Observable<any> {
    if (!emailUser.trim()) {
      return of(`${emailUser} não localizado`);
    }
    return this.http.get<any>(`${APIURL}/usuario?email=${emailUser}`, httpOptions).pipe(
      tap(_ => console.log(`Response getEmailUser`)),
      catchError(this.handleError<any>('getEmailUser', null))
    );
  }

  getUsuarios(): Observable<any> {
        return this.http.get<any>(`${APIURL}/usuarios`, httpOptions).pipe(
      tap(_ => console.log(`Response getUsuarios`)),
      catchError(this.handleError<any>('getUsuarios', null))
    );
  }

  getUsuariosExport(): Observable<any> {
    return this.http.get<any>(`${APIURL}/usuariosExport`, httpOptions).pipe(
  tap(_ => console.log(`Response getUsuariosExport`)),
  catchError(this.handleError<any>('getUsuariosExport', null))
);
}

  create(usuario: Usuario): Observable<any> {
    return this.http.post<Usuario>(`${APIURL}/usuarios`, usuario , httpOptions).pipe(
      tap(_ => console.log(`Response createProcesso`)),
      catchError(this.handleError<Usuario>('createProcesso', null))
    );
  }

  update(usuario: Usuario) {
    return this.http.put<void>(`${APIURL}/usuarios`, JSON.stringify(usuario), httpOptions).pipe(
      tap(_ => console.log(`Response update`)),
      catchError(this.handleError<any>('update', null))
    );
  }

  delete(usuario: Usuario) {
    return this.http.delete<Usuario>(`${APIURL}/usuarios${usuario.id}`, httpOptions).pipe(
      tap(_ => console.log(`Response deleteProcesso`)),
      catchError(this.handleError<Usuario>('deleteProcesso', null))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      console.log(`${operation} failed: ${error.message}`);
      return of(error as T);
    };
  }

  public exportExcel(jsonData: any[], fileName: string): void {

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: this.fileType});
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }

}
