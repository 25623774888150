import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError, timeout } from 'rxjs/operators';
import { Mensagem } from '../models/mensagem.model';
import { environment } from 'src/environments/environment';


const APIURL = environment.api_url_pta;
const headers = environment.HttpHeaders
const httpOptions = { headers }
@Injectable({
  providedIn: 'root'
})
export class MensagemService {

  constructor(private http: HttpClient) { }
  getMensagemPorID(msgID: string): Observable<any> {
    if (msgID == '' || msgID == null) {
      return of(`${msgID} não localizado`);
    }
    return this.http.get<any>(`${APIURL}/mensagem${'?id='}${msgID}`, httpOptions).pipe(
      tap(_ => console.log(`Response getMensagemPorID`)),
      catchError(this.handleError<any>('getMensagemPorID', null))
    )
  }

  // create(mensagem: Mensagem): Observable<any> {
  //   return this.http.post<Mensagem>(`${APIURL}`, mensagem, httpOptions).pipe(
  //     tap(_ => console.log(`Response postSistema`)),
  //     catchError(this.handleError<any>('postSistema', null))
  //   )
  // }

  update(mensagem: Mensagem) {
    console.log(mensagem)
    return this.http.put<Mensagem>(`${APIURL}/mensagens/`, JSON.parse(JSON.stringify(mensagem)), httpOptions).pipe(
      tap(_ => console.log(`Response update`)),
      catchError(this.handleError<any>('update', null))
    )

  }

  // delete(ID: string) {
  //   return this.http.delete<Mensagem>(`${APIURL}sistemas/${ID}`, httpOptions).pipe(
  //     tap(_ => console.log(`Response deleteSistema`)),
  //     catchError(this.handleError<Mensagem>('deleteSistema', null))
  //   )

  // }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}

