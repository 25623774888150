import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap, throwIfEmpty } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RegistroAtividade } from '../models/registro-atividade.model';
import { TipoAtividade } from '../models/tipo-atividade';

const APIURL = environment.api_url_pta;
const APICONSULTA = environment.api_url_post_consulta;
const headers = environment.HttpHeaders;
const httpOptions = { headers }
@Injectable({
  providedIn: 'root'
})

export class CalendarioService {

  constructor(private http: HttpClient) { }

  getEnvioNotificacao(anoReferencia:string): Observable<any> {

    let tipo = TipoAtividade.envioNotificacao;

    const condicaoAno = `AND CONTAINS(c.mesReferenciaNotificacao,'${anoReferencia}')`;

    const query = {'query':'SELECT * FROM c WHERE c.tipo =' + `'${tipo}'` + `${condicaoAno}`};

    return this.http.post<any>(`${APICONSULTA}/pta/colecoes/registro-atividades`, JSON.stringify(query), httpOptions).pipe(
      tap(_ => console.log(`Response getEnvioNotificacao`)),
      catchError(this.handleError<any>('getEnvioNotificacao', null))
    );
  }

  createEnvioNotificacao(registro: RegistroAtividade): Observable<any> {
    return this.http.post<RegistroAtividade>(`${APIURL}/registroatividade`, JSON.stringify(registro) ,httpOptions).pipe(
      tap(_ => console.log(`Response createEnvioNotificacao`)),
      catchError(this.handleError<any>('createEnvioNotificacao', null))
    );
  }

  update(registro: RegistroAtividade) {
    console.log(registro, "registroservice");
    return this.http
      .put<RegistroAtividade>(
        `${APIURL}/registroatividades`,
        registro,
        httpOptions
      )
      .pipe(
        tap((_) => console.log(`Response updateEnvioNotificacao`)),
        catchError(this.handleError<any>("updateEnvioNotificacao", null))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

}
