import { Component, OnInit, Injectable } from '@angular/core';
import { MensagemService } from '../mensagens/mensagem.service';
import { Mensagem } from '../models/mensagem.model';
import { Pipe } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from '../../../src/environments/environment';

const idMensagem = environment.message.id;
// tslint:disable-next-line: use-pipe-transform-interface
@Pipe({ name: 'safeHtml'})
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  mensagem: Mensagem;
  textoMensagem: SafeHtml;
  constructor
     (private mensagemService: MensagemService,
     private sanitized: DomSanitizer) {

    this.getMensagemPorID(idMensagem);
   }

  ngOnInit() {}

  getInnerHTMLValue(value) {
    this.textoMensagem = this.sanitized.bypassSecurityTrustHtml(value);
  }

  getMensagemPorID(msgID: string) {
    this.mensagemService.getMensagemPorID(msgID).subscribe(res => {
      this.mensagem = {
        id: res.mensagem.id,
        titulo: res.mensagem.titulo,
        texto: res.mensagem.texto
      };
      this.getInnerHTMLValue(res.mensagem.texto);
    });
  }
}
