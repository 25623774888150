import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { RelatorioService } from './relatorio.service';
import { RegistroAtividade } from '../models/registro-atividade.model';
import { ExportExcelService } from '../shared/export-excel.service';
import { saveAs } from 'file-saver';
declare var $;

@Component({
  selector: 'app-relatorio',
  templateUrl: './relatorio.component.html',
  styleUrls: ['./relatorio.component.scss']
})

export class RelatorioComponent implements OnInit {
registroAtividades: Array<RegistroAtividade> = [] ;
registroAtividade: RegistroAtividade;
dataSaved: boolean = false;
@ViewChild('dataTable', {static: false}) table: ElementRef;
dataTable: any;
dtOptions: any;
editor: any;
  constructor(
    private relatorioService: RelatorioService,
    private ExportExcel: ExportExcelService) {
   }

  ngOnInit() {
    //this.getRegistroAtividades();
    this.getRegistroAtividadesPorUsuario();
  }


  // getRegistroAtividades() {
  //     this.relatorioService.getRegistroAtividade().subscribe((res)=>{
  //       this.registroAtividades = res.registros;
  //       this.createTable();
  //       //console.log(this.registroAtividades)
  //     },error => {
  //       let erro:number = error || error.messsage
  //    });
  // }


  getRegistroAtividadesPorUsuario() {
    //   if(this.adal.userInfo.authenticated){
    //     var query = {'query':'SELECT * FROM r WHERE r.usuario.email = ' + `'${this.adal.userInfo.userName}'`}
    //     //console.log(query);
    //   this.relatorioService.getRegistroAtividadePorUsuario(query).subscribe((res)=>{
    //     this.registroAtividades = res.Documents;
    //     this.createTable();
    //     // console.log(this.registroAtividades.length);
    //     // console.log(res.Documents);
    //   },error => {
    //     let erro:number = error || error.messsage
    //  });
    // }
  }


  getDownload() {
    this.relatorioService.getDownload(JSON.stringify({'registros': this.registroAtividades}))
    .subscribe((res) => {
    }, error => {
      let erro: number = error || error.messsage;
   });
}


  createTable() {
    this.dtOptions = {
      "processing": true,
      data: this.registroAtividades,
      "language": {
        "sEmptyTable": "Nenhum registro encontrado",
        "sInfo": "Mostrando de _START_ até _END_ de _TOTAL_ registros",
        "sInfoEmpty": "Mostrando 0 até 0 de 0 registros",
        "sInfoFiltered": "(Filtrados de _MAX_ registros)",
        "sInfoPostFix": "",
        "sInfoThousands": ".",
        "sLengthMenu": "_MENU_ resultados por página",
        "sLoadingRecords": "Carregando...",
        "sProcessing": "Processando...",
        "sZeroRecords": "Nenhum registro encontrado",
        "sSearch": "Pesquisar",
        "oPaginate": {
            "sNext": "Próximo",
            "sPrevious": "Anterior",
            "sFirst": "Primeiro",
            "sLast": "Último"
        },
        "oAria": {
            "sSortAscending": ": Ordenar colunas de forma ascendente",
            "sSortDescending": ": Ordenar colunas de forma descendente"
        },
        "select": {
            "rows": {
                "_": "Selecionado %d linhas",
                "0": "Nenhuma linha selecionada",
                "1": "Selecionado 1 linha"
            }
        }
    },
      columns: [
          // { "data": 'id' },
          { "data": 'tipo' },
          { "data": 'nomeProcesso' },
          // { "data": 'idGrupo' },
          { "data": 'nomeArquivo' },
          { "data": 'nomeGrupo' },
          { "data": 'usuario.nome' },
          { "data": 'dataHora', 'render':function name(dataHora) {
            return new Date(dataHora).toLocaleDateString('pt-BR');
          }},
          { "data": 'dataHora', 'render':function name(dataHora) {
            return new Date(dataHora).toLocaleTimeString();
          }}
      ]
    };
    this.dataTable = $(this.table.nativeElement);
    this.dataTable.DataTable(this.dtOptions);
  }

  exportAsXLSX():void {
    this.ExportExcel.exportAsExcelFile(this.registroAtividades, 'RegistroDeAtividades');
 }
}
