import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { tap, catchError, timeout } from 'rxjs/operators';
import { Empresa } from '../models/empresa.model';
import { environment } from 'src/environments/environment';

const APIURL = environment.api_url_pta;
const headers = environment.HttpHeaders;
const httpOptions = { headers };
@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  constructor(private http: HttpClient) { }
  getEmpresaPorID(empresaID: string): Observable<any> {
    if (empresaID === '' || empresaID == null) {
      return of(`${empresaID} não localizado`);
    }
    return this.http.get<any>(`${APIURL}${'/empresa?id=/'}${empresaID}`, httpOptions).pipe(
      // tslint:disable-next-line: comment-format
      timeout(20000), //5 seconds
      tap(_ => console.log(`Response getEmailUser`)),
      catchError(this.handleError<any>('getEmpresaPorID', null))
    );
  }

  getEmpresas(): Observable<any> {
    return this.http.get<any>(`${APIURL}/empresas`, httpOptions).pipe(
      // tslint:disable-next-line: comment-format
      timeout(500000), //50 seconds
      tap(_ => console.log(`Response getEmpresas`)),
      catchError(this.handleError<any>('getEmpresas', null))
    );
  }

  create(empresa: Empresa): Observable<any> {
    return this.http.post<Empresa>(`${APIURL}/empresas`, empresa , httpOptions).pipe(
      tap(_ => console.log(`Response createEmpresa`)),
      catchError(this.handleError<any>('createEmpresa', null))
    );
  }

  update(empresa: Empresa) {
    console.log(empresa);
    return this.http.put<Empresa>(`${APIURL}/empresas`, empresa, httpOptions).pipe(
      tap(_ => console.log(`Response update`)),
      catchError(this.handleError<Empresa>('update', null))
    );
  }

  delete(ID: string) {
    return this.http.delete<Empresa>(`${APIURL}/empresas/${ID}`, httpOptions).pipe(
      tap(_ => console.log(`Response deleteEmpresa`)),
      catchError(this.handleError<Empresa>('deleteEmpresa', null))
    );

  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      if (error.error instanceof ErrorEvent) {
        console.error(error); // log to console instead
        console.log(`${operation} failed: ${error.message}`);
        console.log(`RETORNO STATUS: ${error.status}`);
        console.log(`RETORNO ERRO ERRO: ${error.error}`);
        console.error('RETORNO ERROR ERROR MESSAGE:', error.error.message);
      }
      return of(error);
    };
  }

  handleErrorTeste(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do client
      errorMessage = error.error.message;
    } else {
      // Erro ocorreu no lado do servidor
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

}
