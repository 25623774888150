import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Processo } from '../models/processo.model';
import { environment } from 'src/environments/environment';

const APIURL = environment.api_url_pta;
const headers = environment.HttpHeaders;
const httpOptions = { headers };
@Injectable({
  providedIn: 'root'
})
export class ProcessoService {

  constructor(private http: HttpClient) { }

  getProcessos(): Observable<any> {
    return this.http.get<any>(`${APIURL}/processos`, httpOptions).pipe(
      tap(_ => console.log(`Response getProcessos`)),
      catchError(this.handleError<any>('getProcessos', null))
    );
  }

  getProcessoPorNome(nome: string): Observable<any> {
    return this.http.get<any>(`${APIURL}/processo?nome=${nome}`, httpOptions).pipe(
      tap(_ => console.log(`Response getProcessoPorNome`)),
      catchError(this.handleError<any>('getProcessoPorNome', null))
    )
  }

  createProcesso(processo: Processo): Observable<any> {
    return this.http.post<Processo>(`${APIURL}/processos`, processo, httpOptions).pipe(
      tap(_ => console.log(`Response createProcesso`)),
      catchError(this.handleError<any>('createProcesso', null))
    );
  }

  updateProcesso(processo: Processo) {
    return this.http.put<Processo>(`${APIURL}/processos`, processo, httpOptions).pipe(
      tap(_ => console.log(`Response updateProcesso`)),
      catchError(this.handleError<any>('updateProcesso', null))
    );
  }

  deleteProcesso(processo: Processo) {
    return this.http.delete<Processo>(`${APIURL}/processos/${processo.id}`, httpOptions).pipe(
      tap(_ => console.log(`Response deleteProcesso`)),
      catchError(this.handleError<Processo>('deleteProcesso', null))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      console.log(`${operation} failed: ${error.message}`);
      return of(error);
    };
  }

}
